import { FilterActionTypes } from "app/constants/filterActionTypes"

const initialState = {
    stops: [1, 2, 'infinite'],
    price: {
        min: null,
        max: null
    },
    departureTime: {
        startTime: 0,
        endTime: 24
    },
    returnTime: {
        startTime: 0,
        endTime: 24
    },
    airportDeparture: [],
    airportReturn: [],
    airlines: []
}

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case FilterActionTypes.STOPS_SELECTED:
            return { ...state, stops: action.payload }
        case FilterActionTypes.PRICE_RANGE:
            console.log('acasc', action.payload);
            return {
                ...state, price: { ...state.price, min: action.payload[0], max: action.payload[1] }
            }
        case FilterActionTypes.DEPARTURE_TIME_RANGE:
            return {
                ...state, departureTime: { ...state.departureTime, startTime: action.payload[0], endTime: action.payload[1] }
            }
        case FilterActionTypes.RETURN_TIME_RANGE:
            return {
                ...state, returnTime: { ...state.returnTime, startTime: action.payload[0], endTime: action.payload[1] }
            }
        case FilterActionTypes.AIRPORT_DEPARTURE_SELECTED:
            return {
                ...state, airportDeparture: action.payload
            }
        case FilterActionTypes.AIRPORT_RETURN_SELECTED:
            return {
                ...state, airportReturn: action.payload
            }
        case FilterActionTypes.AIRLINES:
            return {
                ...state, airlines: action.payload
            }
        case FilterActionTypes.CLEAR_FILTERS:
            return {
                ...initialState
            }
        default:
            return state
    }
}