import {ImAirplane} from "react-icons/im"
import {IoCalendar} from "react-icons/io5"
import {ImPriceTags} from "react-icons/im"
const FlightsFeatures=()=>{
    return (
    <>
    <div className="w-[93%] flex flex-wrap m-auto justify-between">
        <div className="w-full h-[80px] sm:h-[100px] m-[15px] p-[15px] rounded-lg sm:w-[45%] lg:w-[30%] border-solid border-gray-400 border flex flex-nowrap items-center">
            <div className="text-4xl text-[#F70F36] mr-[15px] rotate-90">
            <ImAirplane/>
            </div>
            <div className="font-semibold text-sm">
                Explore the flight deals from anywhere to everywhere, then book with no fees
            </div>
        </div>
        <div className="w-full h-[80px] sm:h-[100px] m-[15px] p-[15px] rounded-lg sm:w-[45%] lg:w-[30%] border-solid border-gray-400 border flex flex-nowrap items-center">
            <div className="text-4xl text-[#F70F36] mr-[15px]">
            <IoCalendar/>
            </div>
            <div className="font-semibold text-sm">
                Compare flight deals from over 500 providers, and choose the best, fastest or greenest tickets
            </div>
        </div>
        <div className="w-full h-[80px] sm:h-[100px] m-[15px] p-[15px] rounded-lg sm:w-[45%] lg:w-[30%] border-solid border-gray-400 border flex flex-nowrap items-center">
            <div className="text-4xl text-[#F70F36] mr-[15px]">
            <ImPriceTags/>
            </div>
            <div className="font-semibold text-sm">
                Get the cheapest flight deal in a matter of seconds
            </div>
        </div>
        
    </div>
    </>
    );
}
export default FlightsFeatures;