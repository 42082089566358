import { flightActionTypes } from "app/constants/flightActionTypes"


const initialState = {
    flights: [],
    fareRule: null,
    fareQuote: null,
    passengers: [],
    error: '',
    returnSelectedFlights: {
        OB: null,
        IB: null,
    },
    passengerDetails: null,
    cheapestFlight: null,
    shortestFlight: null,
    canadaSeats: null,
    indiaSeats: null
}

export const flightsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        // case flightActionTypes.TRACE_ID:
        //     return { ...state, traceId: action.payload }
        case flightActionTypes.FLIGHT_FOUND:
            return {
                ...state, flights: action.payload.flights, error: action.payload.error, fareQuote: null, fareRule: null, returnSelectedFlights: {
                    OB: null,
                    IB: null,
                }
            }
        case flightActionTypes.FARE_RULE:
            return { ...state, fareRule: action.payload }
        case flightActionTypes.FARE_QUOTE:
            return { ...state, fareQuote: action.payload }
        case flightActionTypes.RETURN_FLIGHT_SELECTED:
            let data = action.payload;
            if (data.type === 'depart') {
                return { ...state, returnSelectedFlights: { ...state.returnSelectedFlights, OB: data.flight } }
            } else {
                return { ...state, returnSelectedFlights: { ...state.returnSelectedFlights, IB: data.flight } }
            }
        case flightActionTypes.REMOVE_SELECTED_FLIGHTS:
            return {
                ...state, returnSelectedFlights: {
                    OB: null,
                    IB: null
                }
            }
        case flightActionTypes.PASSENGER_DETAILS:
            return {
                ...state,
                passengerDetails: action.payload
            }

        case flightActionTypes.CHEAPEST_FLIGHT:
            return {
                ...state,
                cheapestFlight: action.payload
            }
        case flightActionTypes.SHORTEST_FLIGHT:
            return {
                ...state,
                shortestFlight: action.payload
            }
        case flightActionTypes.RESET_FLIGHTS:
            // console.log('inside reset Flihgts');
            return {
                ...initialState
            }
        case flightActionTypes.RESET_FAREQUOTE_AND_FARERULE:
            return {
                ...state,
                fareRule: null,
                fareQuote: null,
            }
        case flightActionTypes.GET_SEATS:
            const { seats, type } = action.payload;
            return {
                ...state,
                canadaSeats: type === "CA" || type === "US" ? seats : null,
                indiaSeats: type === "IN" ? seats : null,
            }
        default:
            return state
    }
}