import { useDispatch } from "react-redux";
import { useTimer } from "react-timer-hook";
import { useEffect } from "react"
function MyTimer({ timerStarted }) {
    const time = new Date();
    const dispatch = useDispatch()
    time.setSeconds(time.getSeconds() + 900);
    const {
        seconds,
        minutes,
        isRunning,
        restart,
    } = useTimer({
        expiryTimestamp: time,
        autoStart: false,
        onExpire: () => {
            if (window.location.pathname !== "/policy/baggage-policy" || window.location.pathname !== "/FlightSearch" || !window.location.pathname.includes('itinerary') || !window.location.pathname.includes('pay-done')) {
                import('../../app/actions/searchActions').then(action => dispatch(action.setSessionExpiredTrue()))
            }
            import('../../app/actions/searchActions').then(actions => dispatch(actions.endTimer()));
        }
    });


    const getTimeStamp = () => {
        const time = new Date();
        time.setSeconds(time.getSeconds() + 900); //900
        restart(time);
    }
    useEffect(() => {
        if (timerStarted) {
            if (isRunning) {
                getTimeStamp()
            } else {
                getTimeStamp()
                // start()
            }
        }
    }, [timerStarted])

    // if (!timerStarted) return <></>

    return (
        <div className="hidden bottom-10 z-40  w-1/2  left-0  justify-center right-0 translate-x-1/2">
            <div className="flex justify-center items-center bg-gray-300 px-6  py-2 rounded-lg">
                <p className="font-semibold text-xl">Your Session Will Expire in: </p> &nbsp;
                <span className="font-semibold text-xl">{minutes.toString().padStart(2, 0)}</span>:
                <span className="font-semibold text-xl">{seconds.toString().padStart(2, 0)} mins</span>
            </div>
        </div>
    );
}

export default MyTimer