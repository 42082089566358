import React, { FC, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useQueryClient } from "@tanstack/react-query";
import { getUser, signInWithEmail } from "utils/auth";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { auth, db } from "config/firebase-config";
import { GoogleAuthProvider, sendPasswordResetEmail, signInWithPopup } from "firebase/auth";
import SnackBar from "shared/SnackBar/SnackBar";
import isEmail from "validator/lib/isEmail";
import { addDoc, collection, doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
    function: 'signInWithGoogle'
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const dispatch = useDispatch();
  const googleProvider = new GoogleAuthProvider();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [state, setState] = useState({
    email: '',
    password: "",
  })
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: ''
  });
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      if (state.email === '' || !isEmail(state.email)) return setValidationErrors({ ...validationErrors, email: "Invalid Email!" })
      setLoading(true)
      const uid = await signInWithEmail({ email: state.email, password: state.password });
      await queryClient.invalidateQueries({ queryKey: ['getUser'] });
      validateUser(uid)
    } catch (error) {
      window.alert(error);
      setLoading(false)
    }
  }


  const validateUser = async (uid: any) => {
    if (uid.message === '') {
      setValidationErrors({ email: '', password: '' })
      const user = await getUser();
      import('../../app/actions/userActions').then((action: any) => dispatch(action.foundUser(user)));
      setLoading(false)
      navigate('/');
    } else {
      if (uid.message === 'Incorrect Email') {
        setValidationErrors({ ...validationErrors, email: uid.message, password: '' })
      } else {
        setValidationErrors({ ...validationErrors, password: uid.message, email: "" })
      }
      setLoading(false)
    }
  }

  const handleForgotPassword = async (e: any) => {
    e.preventDefault();
    // setIsSnackBarOpen(true)
    if (state.email === '' || !isEmail(state.email)) {
      return setValidationErrors({ ...validationErrors, email: 'Invalid Email!' })
    }
    setValidationErrors({ ...validationErrors, email: '', password: '' })
    sendPasswordResetEmail(auth, state.email)
      .then(() => {
        setIsSnackBarOpen(true)
        return
      })
      .catch((error: any) => {
        if (error.message.includes('user-not-found')) return setValidationErrors({ ...validationErrors, email: "User Not Found!" })
      });
    return
  }


  const handleChange = (name: string, value: string) => {
    setState({ ...state, [name]: value });
  }

  const checkUserInDb = async (uid: any) => {
    console.log({ uid });

    const userRef = doc(db, 'Users', uid);
    const docs = await getDoc(userRef);
    console.log('docs Data', docs.data());

    if (!docs.data()) {
      return false
    } else {
      return docs.data();
    }
  }

  const signInWithGoogle = async () => {
    try {
      const res = await signInWithPopup(auth, googleProvider);
      const user = res.user;
      let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
      let countryData = await data.json();
      try {
        localStorage.setItem('@auth-token', user.uid)
      } catch (error) {
        console.log(error);
      }
      const q = await checkUserInDb(user.uid);
      if (!q) {
        await setDoc(doc(db, "Users", user?.uid), {
          id: user.uid,
          name: user.displayName,
          authProvider: "google",
          email: user.email,
          createdAt: serverTimestamp(),
          country: countryData?.country
        });
      }
      checkUserInDb(user.uid)
        .then((res: any) => {
          import('../../app/actions/userActions').then(userActions => {
            return dispatch(userActions.foundUser(res))
          })
          setValidationErrors({ ...validationErrors, email: '' });
          // setIsOpen(false)
          if (res) {
            navigate('/')
            console.log('signed in');
          }
          else
            setValidationErrors({ ...validationErrors, email: 'User Not Found' });
        })
      // setIsOpen(false)
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32">
        <SnackBar isOpen={isSnackBarOpen} message="Password Reset Mail Sent!" setOpen={setIsSnackBarOpen} />
        <h2 className="my-10 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
                onClick={(e: any) => {
                  e.preventDefault();
                  item?.function === 'signInWithGoogle' && signInWithGoogle()
                }}
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div>
          {/* OR */}
          <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div>
          {/* FORM */}
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit} >
            <label className="block">
              <span className="text-neutral-800 dark:text-neutral-200">
                Email address
              </span>
              <Input
                name="email"
                value={state.email}
                onChange={(e => handleChange(e.target.name, e.target.value))}
                type="email"
                placeholder="example@example.com"
                className="mt-1"
              />
              {validationErrors.email !== '' &&
                <label className="text-xs text-red-600 font-normal">{validationErrors.email}</label>
              }
            </label>
            <label className="block">
              <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                Password
                <Link to="/forgot-pass" className="text-sm" onClick={(e: any) => handleForgotPassword(e)}>
                  Forgot password?
                </Link>
              </span>
              <Input type="password"
                name="password"
                value={state.password}
                onChange={(e => handleChange(e.target.name, e.target.value))}
                className="mt-1" />
              {validationErrors.password !== '' &&
                <label className="text-xs text-red-600 font-normal">{validationErrors.password}</label>
              }
            </label>
            {loading ?
              <ButtonPrimary disabled>
                <CircularProgress size={25} style={{ color: '#fff' }} />
              </ButtonPrimary>
              :
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            }
          </form>

          {/* ==== */}
          <span className="block text-center text-neutral-700 dark:text-neutral-300">
            New user? {` `}
            <Link to="/signup">Create an account</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
