import React from "react";
import { Link } from "react-router-dom";
import logoLightImg from "images/logo-light.png";
import LogoSvgLight from "./LogoSvgLight";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  currency?: any
}

const Logo: React.FC<LogoProps> = ({
  img,
  imgLight = logoLightImg,
  className = "",
  currency
}) => {
  return (
    <Link
      to={`/?cur=${currency?.name}`}
      className={` focus:outline-none focus:ring-0 ${className}`}
    >

      <LogoSvgLight logo={img} />
      {/* <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      {/* {img ? (
        <img
          className={`block max-h-12 ${imgLight ? "dark:hidden" : ""}`}
          src={img}
          alt="Logo"
        />
      ) : (
        "Logo Here"
      )}
      {imgLight && (
        <img
          className="hidden max-h-12 dark:block"
          src={imgLight}
          alt="Logo-Light"
        />
      )} */}
    </Link>
  );
};

export default Logo;
