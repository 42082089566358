import { db } from "config/firebase-config";
import { doc, getDoc } from "firebase/firestore";

export const getCategoryData = async () => {
    const data = await getDoc(doc(db, 'HomeData', 'Home'));
    if (data) {
        return data.data()
    } else {
        return false
    }
}