import React, { FC } from 'react'
import "./easySteps.css";
import tourist from "../../../../images/newHome/tourist.png"
// import tourist from "../../../../images/newHome/tourist.png"
import plan from "../../../../images/newHome/plan.png"
import globe from "../../../../images/newHome/globe.png"
// import debit from "../../../../images/newHome/debit-card.png"
import priceTag from "../../../../images/newHome/price-tags.png"
// import checkin from "../../../../images/newHome/check-in.png"
import booking from "../../../../images/newHome/file.png"
import HoppingPlane from "../../../../images/newHome/hoppingPlane.png"
import { useMediaQuery } from '@mui/material';

export interface EaseStepsProps {
    className?: any
}

const EaseSteps: FC<EaseStepsProps> = ({ className }) => {
    const matches = useMediaQuery('(max-width:640px)');
    return (
        <div className={`w-[93%] mx-auto sm:py-12 pb-6  ${className} flex justify-center`}>
            <div className='img-container lg:w-[45%] xl:w-[30%] hidden md:flow-root flex-1 md:flex justify-center w-full'>
                <img className='tourist w-full m-auto h-full' src={tourist} loading="lazy" alt="" />
            </div>
            <img src={HoppingPlane} className="absolute right-[55%] " alt="" />
            {/* remove margin later */}
            <div className={`content flex-1 ${matches ? "" : "pl-[40px]"} box-border`}>
                <div className='text-2xl md:text-3xl lg:text-4xl  font-semibold sm:text-left text-center'>Book Your Next Flight in 4 Easy Steps</div>
                {/* <p className='text-base text-gray-500 mt-2 '>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo, cum?</p> */}
                <div className='icons-container flex flex-col w-full sm:w-4/5 sm:mt-8 md:mt-10 lg:mt-12 mt-6 lg:gap-8 md:gap-4 sm:gap-4 gap-8'>
                    <div className='flex gap-4 items-center'>
                        <div className='icon'><img src={plan} alt="" /></div>
                        <div className=' flex flex-col'>
                            <h4 className='text-base md:text-lg lg:text-xl xl:text-2xl font-semibold '>Plan Your Travel…</h4>
                            <p className='text-xs md:text-sm lg:text-base text-gray-500'>Check Your Bucket List & Plan Your Next Travel with a Loved One OR Family</p>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div className='icon'><img src={globe} alt="" /></div>
                        <div className=' flex flex-col'>
                            <h4 className='text-base  md:text-lg  lg:text-xl  xl:text-2xl  font-semibold '>Outline Your Travel Stops…</h4>
                            <p className='text-xs  md:text-sm  lg:text-base text-gray-500'>Select Your Choice of Destination(s) & Date(s) You would to Travel</p>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div className='icon'><img src={priceTag} alt="" /></div>
                        <div className=' flex flex-col'>
                            <h4 className='text-base  md:text-lg  lg:text-xl  xl:text-2xl font-semibold '>Compare & Book…</h4>
                            <p className='text-xs  md:text-sm  lg:text-base  text-gray-500'>Always try to Compare & Book approx. 6 Weeks in Advance if you can, for Best Flight Deals & Prices</p>
                        </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                        <div className='icon'><img src={booking} alt="" /></div>
                        <div className=' flex flex-col'>
                            <h4 className='text-base  md:text-lg  lg:text-xl xl:text-2xl  font-semibold '> Book & Print…</h4>
                            <p className='text-xs  md:text-sm  lg:text-base  text-gray-500'> Keep Your Friends Close But Your Booking Details & Other Relevant Documents Closer so that you travel without commotion</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default EaseSteps