import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Page } from "./types";
// import { useSelector } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import PageOffers from "containers/OffersPage/PageOffers";

// import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
// import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
// import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
// import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
// import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
// import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
// import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
// import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
// import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
// import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
// import AuthorPage from "containers/AuthorPage/AuthorPage";
// import AccountPage from "containers/AccountPage/AccountPage";
// import AccountPass from "containers/AccountPage/AccountPass";
// import AccountSavelists from "containers/AccountPage/AccountSavelists";
// import AccountBilling from "containers/AccountPage/AccountBilling";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
// import PageSubcription from "containers/PageSubcription/PageSubcription";
// import BlogPage from "containers/BlogPage/BlogPage";
// import BlogSingle from "containers/BlogPage/BlogSingle";
// import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
// import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
// import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
// import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
// import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
// import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
// import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
// import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
// import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
// import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
// import PageHome2 from "containers/PageHome/PageHome2";
// import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
// import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
// import FooterNav from "components/FooterNav";
// import useWindowSize from "hooks/useWindowResize";
// import { ItineraryDetails } from 'containers/ItineraryDetails/ItineraryDetails';
// import PageHome3 from "containers/PageHome/PageHome3";
// import BookingsPage from "containers/MyBookingsPage/BookingsPage";
// import PolicyContent from "shared/Footer/PolicyContent";
import { useSelector } from "react-redux";
// import { store } from "app/store";
// import FareRules from "containers/ItineraryDetails/FareRules";
import MyTimer from "components/Timer/Timer";
// import SessionExpired from "components/SessionExpired/SessionExpired";
import { Backdrop } from "@mui/material";
import Loading from "shared/Loading/Loading";
import FlightsPage from "containers/FlightsPage/FlightsPage";
import PaymentFail from "containers/Payment/PaymentFail";
import BookingFailed from "containers/BookingFailed/BookingFailed";
import Blog from "containers/Blog/Blog";
import SingleBlog from "containers/Blog/SingleBlog";
// import Page404 from "containers/Page404/Page404";
// import PageContact from "containers/PageContact/PageContact";
// import PageAbout from "containers/PageAbout/PageAbout";
// import PayPage from "containers/PayPage/PayPage";

// const ListingFlightsPage = lazy(() => import("containers/ListingFlightsPage/ListingFlightsPage"));
const BookingsPage = lazy(
  () => import("containers/MyBookingsPage/BookingsPage")
);
const PolicyContent = lazy(() => import("shared/Footer/PolicyContent"));
const SessionExpired = lazy(
  () => import("components/SessionExpired/SessionExpired")
);
const FareRules = lazy(() => import("containers/ItineraryDetails/FareRules"));
const PayPage = lazy(() => import("containers/PayPage/PayPage"));
const PageAbout = lazy(() => import("containers/PageAbout/PageAbout"));
const PageContact = lazy(() => import("containers/PageContact/PageContact"));
const ItineraryDetails = lazy(
  () => import("containers/ItineraryDetails/ItineraryDetails")
);
const Page404 = lazy(() => import("containers/Page404/Page404"));

// export const pages: Page[] = [

//   { path: "/", exact: true, component: PageHome },
//   // { path: "/#", exact: true, component: PageHome },
//   // { path: "/home-1-header-2", exact: true, component: PageHome },
//   // { path: "/home-2", component: PageHome2 },
//   // { path: "/home-3", component: PageHome3 },
//   //
//   // { path: "/listing-stay", component: ListingStayPage },
//   // { path: "/listing-stay-map", component: ListingStayMapPage },
//   // { path: "/listing-stay-detail", component: ListingStayDetailPage },
//   //
//   // {
//   //   path: "/listing-experiences",
//   //   component: ListingExperiencesPage,
//   // },
//   // {
//   //   path: "/listing-experiences-map",
//   //   component: ListingExperiencesMapPage,
//   // },
//   // {
//   //   path: "/listing-experiences-detail",
//   //   component: ListingExperiencesDetailPage,
//   // },
//   {
//     path: "/fare-rule",
//     component: FareRules,
//   },
//   // {
//   //   path: "/fare-rule",
//   //   component: FareRules,
//   // },
//   { path: "/FlightSearch", component: ListingFlightsPage },
//   {path:"/offers/flight-tickets",component:PageOffers},

//   { path: "/flights", component: FlightsPage },
//   { path: "/flight-itinerary/:resultIndex", component: ItineraryDetails },
//   { path: "/flight-itinerary/:OB/:IB", component: ItineraryDetails },
//   { path: "/pay-done", component: PayPage },
//   { path: "/bookings", component: BookingsPage },
//   { path: "/contact", component: PageContact },
//   { path: "/about", component: PageAbout },
//   { path: "/signup", component: PageSignUp },
//   { path: "/login", component: PageLogin },
//   // { path: "/subscription", component: PageSubcription },
//   { path: "/policy/:name", component: PolicyContent },
//   { path: "/payment-failed", component: PaymentFail },
//   { path: "/booking-processing", component: BookingFailed },
//   { path: "/blog", component: Blog},
//   { path: "/blog/:slug", component: SingleBlog}
//   //
// ];

const MyRoutes = () => {
  const { currency } = useSelector((state: any) => state.appReducer);
  // const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  const { timerStarted, sessionExpired } = useSelector(
    (state: any) => state.searchReducer
  );
  let ele = document.querySelector("body");
  if (sessionExpired) {
    // Disable scroll
    ele!.style.overflow = "hidden";
  } else {
    // Enable scroll
    ele!.style.overflow = "auto";
  }

  const pages: Page[] = [
    { path: "/", exact: true, component: PageHome },
    // { path: "/#", exact: true, component: PageHome },
    // { path: "/home-1-header-2", exact: true, component: PageHome },
    // { path: "/home-2", component: PageHome2 },
    // { path: "/home-3", component: PageHome3 },
    //
    // { path: "/listing-stay", component: ListingStayPage },
    // { path: "/listing-stay-map", component: ListingStayMapPage },
    // { path: "/listing-stay-detail", component: ListingStayDetailPage },
    //
    // {
    //   path: "/listing-experiences",
    //   component: ListingExperiencesPage,
    // },
    // {
    //   path: "/listing-experiences-map",
    //   component: ListingExperiencesMapPage,
    // },
    // {
    //   path: "/listing-experiences-detail",
    //   component: ListingExperiencesDetailPage,
    // },
    {
      path: "/fare-rule",
      component: FareRules,
    },
    // {
    //   path: "/fare-rule",
    //   component: FareRules,
    // },
    { path: "/FlightSearch", component: ListingFlightsPage },
    { path: `/offers/flight-tickets`, component: PageOffers },
    { path: "/flights", component: FlightsPage },
    { path: "/flight-itinerary/:resultIndex", component: ItineraryDetails },
    { path: "/flight-itinerary/:OB/:IB", component: ItineraryDetails },
    { path: "/pay-done", component: PayPage },
    { path: "/bookings", component: BookingsPage },
    { path: "/contact", component: PageContact },
    { path: "/about", component: PageAbout },
    { path: "/signup", component: PageSignUp },
    { path: "/login", component: PageLogin },
    // { path: "/subscription", component: PageSubcription },
    { path: "/policy/:name", component: PolicyContent },
    { path: "/payment-failed", component: PaymentFail },
    { path: "/booking-processing", component: BookingFailed },
    { path: "/blogs", component: Blog },
    { path: "/blogs/:slug", component: SingleBlog },
    //
  ];

  return (
    <BrowserRouter>
      <ScrollToTop />

      {window.location.pathname !== "/fare-rule/" && <SiteHeader />}

      <Backdrop style={{ zIndex: "50" }} open={sessionExpired}>
        <SessionExpired />
      </Backdrop>
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <Route
            path="/"
            render={({ location }) => {
              window.gtag('config', 'UA-XXXXXXXXX', {
                page_path: location.pathname + location.search,
              });
            }}
          /> */}
          {pages.map(({ component, path }) => {
            const Component = component;
            if (path === "/FlightSearch") {
              return (
                <Route
                  key={path}
                  element={
                    <>
                      <MyTimer timerStarted={timerStarted} />
                      <Component />
                    </>
                  }
                  path={path}
                ></Route>
              );
            }
            // if (path === `/offers/flight-tickets`) {
            //   console.log({ path });
            //   const newURL = path + `?cur=${currency?.name}`;
            //   console.log("new ", newURL);
            // }

            // offers page start
            // if (path === `/offers/flight-tickets`) {
            //   const newURL = path + `?cur=${currency?.name}`;
            //   // console.log(newURL,"newurl")
            //   window.history.pushState(null, "", newURL);
            //   return (
            //     <Route
            //       key={`/offers/flight-tickets`}
            //       element={
            //         <>
            //           <MyTimer timerStarted={timerStarted} />
            //           <Component />
            //         </>
            //       }
            //       path={`/offers/flight-tickets`}
            //     ></Route>
            //   );
            // }
            // offers page end

            return (
              <Route
                key={path}
                element={
                  <>
                    <MyTimer timerStarted={timerStarted} />
                    <Component />
                  </>
                }
                path={path}
              />
            );
          })}
          <Route element={<Page404 />} />
        </Routes>
      </Suspense>

      {/* {WIN_WIDTH < 768 && <FooterNav />} */}

      {window.location.pathname !== "/fare-rule/" && <Footer />}
    </BrowserRouter>
  );
};

export default MyRoutes;
