
const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
}
const { serverUrl: url } = require('../utils/urls')

// const url = 'http://localhost:4242';

export const searchFlights = async ({ country, data }) => {
    console.log('search started', data);
    try {
        const response = await fetch(`${url}/search-flights`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ data, country })
        })
        const res = await response.json();
        console.log('search response', { res });
        if (res?.status === 500) {
            return {
                status: 500,
                traceId: '',
                results: [],
                error: res?.data?.Response?.Error?.ErrorMessage || ''
            }
        }
        if (res?.data?.Response?.Error?.ErrorCode !== 0) {
            return {
                status: 500,
                traceId: '',
                results: [],
                error: res?.data?.Response?.Error?.ErrorMessage
            }
        }
        console.log({ res });
        if (res) {
            return {
                status: 200,
                traceId: res?.data?.Response?.TraceId,
                results: res?.data.Response?.Results,
                error: res?.data.Response?.Results.length === 0 ? "No Result Found" : res?.data?.Response?.Error?.ErrorMessage
            }
        } else {
            return {
                status: 500,
                traceId: '',
                results: [],
                error: "No Result Found"
            }
        }
    } catch (error) {
        return {
            status: 500,
            traceId: '',
            results: [],
            error: "No Result Found"
        }
    }
}

export const getFareRule = async ({ data, country, flight }) => {
    try {

        const response = await fetch(`${url}/fare-rule`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ data, country, flight })
        })
        const res = await response.json();

        return res.data.Response;
    } catch (error) {
        console.log({ error });
    }
}

export const getFareQuote = async ({ data, country, flight }) => {
    try {

        const response = await fetch(`${url}/fare-quote`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ data, country, flight })
        })
        const res = await response.json();
        return res.data.Response;
    } catch (error) {
        console.log({ error });
    }
}

export const bookFlight = async ({ data, country, fareOffer }) => {
    try {
        const response = await fetch(`${url}/book-flight`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ data, country, fareOffer })
        })
        const res = await response.json();

        console.log('resss', res);
        return res.Response;
    } catch (error) {
        console.log({ error });
        return error
    }
}

export const bookTicket = async ({ data, country }) => {
    try {
        const response = await fetch(`${url}/book-ticket`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ data, country })
        })
        const res = await response.json();
        return res.Response;
    } catch (error) {
        console.log({ error });
        return error
    }
}


export const easebuzzPayment = async ({ data, country }) => {
    try {
        const response = await fetch(`${url}/initiate-payment`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                data, country
            })
        });
        const res = await response.json()
        console.log({ res });
        return res

    } catch (error) {
        console.log('ee', { error });
    }
}


export const getSeatDetails = async ({ data, country, flight }) => {
    try {
        console.log('insidwe seat detaisl', { data, country, flight });
        const response = await fetch(`${url}/get-seat-details`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({ data, country, flight })
        })
        const res = await response.json();
        console.log({ res });
        return res;
    } catch (error) {
        console.log({ error });
    }
}
