import React, { useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import styles from "./styles/flightSearchForm.module.css"
import landing from "../../images/newHome/landing.png"
import takeoff from "../../images/newHome/takeoff.png"
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from "react-router-dom";
import typesense_initClient from "config/typesense";
export interface LocationInputProps {
  defaultValue: string;
  onChange?: (value: string) => void;
  getClientData?: any;
  onInputDone?: (value: string) => void;
  placeHolder?: string;
  desc?: string;
  className?: string;
  isFullWidth?: boolean;
  isMultiCity?: boolean;
  searchResults?: any;
  autoFocus?: boolean;
  index?: any,
  isSource?: boolean;
}

const LocationInput: FC<LocationInputProps> = ({
  defaultValue,
  autoFocus = false,
  onChange,
  onInputDone,
  index,
  isFullWidth,
  isSource,
  getClientData,
  searchResults,
  isMultiCity,
  placeHolder = "Location",
  desc = "Where are you going?",
  className = "nc-flex-1.5",
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [urlSearchParams] = useSearchParams()
  const inputRef = useRef<HTMLInputElement>(null);
  const { searchData, multiSearchFullValues } = useSelector((state: any) => state.searchReducer)
  const { currency } = useSelector((state: any) => state.appReducer)
  const [value, setValue] = useState(defaultValue);
  const [showPopover, setShowPopover] = useState(autoFocus);
  const dispatch = useDispatch()
  // var getDebouncedResults = _.debounce(() => getClientData(value), 500);
  const [fullVal, setFullVal] = useState<String>(desc);
  const matches = useMediaQuery('(min-width:600px)');

  async function getTypeSenseData(searchKey: any) {
    let searchRequests = {
      'searches': [
        {
          'collection': 'airports',
          'q': searchKey,
          'group_by': 'city_name',
          'per_page': 250,
          'filter_by': `country_code:=IN`
        },
        {
          'collection': 'airports',
          'q': searchKey,
          'group_by': 'city_name',
          'per_page': 250,
          'filter_by': `country_code:!=IN`
        },
      ]
    }
    let commonSearchParams = {
      'query_by': 'airport_code,city_code',
    }

    try {
      let client = await typesense_initClient();
      // let data = await client.collections('airports').documents().search(searchParameters)

      let data = await client.multiSearch.perform(searchRequests, commonSearchParams)
      console.log('locattion typeds', data);
      let doc = data?.results[0]?.grouped_hits[0].hits[0].document
      let fullValue = `${doc?.airport_name}, ${doc?.city_name}, ${doc?.country_name}`
      //  airport city country
      setFullVal(fullValue)
    } catch (error) {
      console.log({ error });
    }
  }

  function getFullValue() {
    if (urlSearchParams.get('triptype') === "1") {
      if (isSource) {
        let code = urlSearchParams.get('from')
        getTypeSenseData(code)
      } else {
        let code = urlSearchParams.get('to')
        getTypeSenseData(code)
      }
    } else {
      if (isSource) {
        let code = urlSearchParams.get('from1')
        getTypeSenseData(code)
      } else {
        let code = urlSearchParams.get('to1')
        getTypeSenseData(code)
      }
    }
  }

  useEffect(() => {
    getClientData(value)
  }, [value])

  useEffect(() => {
    if (urlSearchParams.get('triptype')) {
      getFullValue()
    }
  }, [])

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string, fullValue: string) => {
    if (index === undefined) {
      setValue(item);
      setFullVal(fullValue);
      import('../../app/actions/searchActions').then(searchAction => dispatch(searchAction.getPlaces({ type: isSource ? 'source' : 'destination', value: item })))
      onInputDone && onInputDone(item);
      setShowPopover(false);
    } else {
      let segments: any = [];
      let fullValues: any = []
      searchData?.Segments?.map((segment: any, idx: any) => {
        if (parseInt(index) === idx) {
          segments.push({ ...segment, Origin: isSource ? item : segment.Origin, Destination: isSource ? segment.Destination : item })
          // console.log('ascsacaas');

          fullValues.push({ ...multiSearchFullValues[idx], origin: isSource ? fullValue : multiSearchFullValues[idx]?.origin || '', destination: isSource ? multiSearchFullValues[idx]?.destination || '' : fullValue })
          // console.log('aaaaaaaaaaaaaa');
          setShowPopover(false);
        } else {
          segments.push(segment)
          // console.log('asjcgasugcugb');

          fullValues.push(multiSearchFullValues[idx] || null)
        }
      })
      import('../../app/actions/searchActions').then(searchAction => dispatch(searchAction.getSegment(segments)))
      import('../../app/actions/searchActions').then(searchAction => dispatch(searchAction.getMultiSearchFullValues(fullValues)))
    }
  };

  const singleHit = (data: any, isAllAirports: boolean, idx: number) => {
    if (isAllAirports) {
      return <span
        key={idx}
        onClick={() => {
          handleSelectLocation(data.hits[0]?.document.city_code, data.group_key[0] + " All Airports, " + data.hits[0]?.document.city_name + " " + data.hits[0]?.document.country_name)
        }}
        className="flex px-4 sm:px-4 items-center space-x-1 sm:space-x-4 py-3 sm:py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
      >
        <span className="flex justify-center items-center font-medium text-neutral-700 dark:text-neutral-200">
          <i className='flaticon-two-plane text-primary-6000 text-2xl' /> <span className="pl-2"> {data.group_key[0]},&nbsp;
            All Airports, &nbsp;
            {data.hits[0].document.city_name},&nbsp;
            {data.hits[0].document.country_name}&nbsp;
          </span>
        </span>
      </span>
    }
    return <span
      key={idx}
      onClick={() => handleSelectLocation(data.hits[0]?.document.city_code,
        data.hits[0].document.airport_name + ", " +
        data.hits[0].document.city_name + ", " +
        data.hits[0].document.country_name)}
      className="flex px-4 sm:px-4 items-center space-x-1 sm:space-x-4 py-3 sm:py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
    >
      <span className="flex justify-center items-center font-medium text-neutral-700 dark:text-neutral-200">
        <i className='flaticon-black-plane text-primary-6000 text-2xl' /> <span className="pl-2">{data.hits[0].document.airport_code}&nbsp;-&nbsp;
          {data.hits[0].document.airport_name},&nbsp;
          {data.hits[0].document.city_name},&nbsp;
          {data.hits[0].document.country_name}
        </span>
      </span>
    </span>
  }

  const multiHit = (res: any, idx: number) => {
    return <>
      <span
        key={idx}
        onClick={() => {
          handleSelectLocation(res?.hits[0]?.document?.city_code, res.group_key[0] +
            " All Airports, " +
            res.hits[0].document.city_name + " " +
            res.hits[0].document.country_name)
        }}
        className="flex px-6 sm:px-4 items-center space-x-1 sm:space-x-4 py-3 sm:py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
      >
        <span className=" font-medium text-neutral-700 dark:text-neutral-200 flex justify-center items-center">
          <i className='flaticon-two-plane text-primary-6000 text-2xl' /><span className="pl-2">{res.group_key[0]},&nbsp;
            All Airports, &nbsp;
            {res.hits[0].document.city_name},&nbsp;
            {res.hits[0].document.country_name}&nbsp;
          </span>
        </span>
      </span>
      {
        res.hits.map((hit: any, idx: number) => {
          return <span
            onClick={() => handleSelectLocation(hit.document.airport_code,
              hit.document.airport_name + ", " +
              hit.document.city_name + ", " +
              hit.document.country_name)}
            key={idx}
            className="flex px-4 sm:pl-10 items-center space-x-1 sm:space-x-4 py-3 sm:py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className=" font-medium text-neutral-700 dark:text-neutral-200 flex justify-center items-center">
              <i className='flaticon-right-down text-primary-6000 text-2xl font-bold' />&nbsp; <span className="pl-0">{hit.document.airport_code}&nbsp;-&nbsp;
                {hit.document.airport_name},&nbsp;
                {hit.document.city_name},&nbsp;
                {hit.document.country_name}
              </span>
            </span>
          </span>
        })
      }
    </>
  }


  const renderSearchValue = () => {
    console.log(matches, "matches", searchResults)
    if ((!isFullWidth) && matches) {
      window.scrollTo({
        top: 200,
        behavior: 'smooth'
      });
    }
    return <>
      {searchResults?.length === 0 ?
        <h2 className="text-center font-semibold">No results found</h2>
        : searchResults && searchResults.map((res: any, index: any) => {
          if (index === 0) {
            if (res.hits.length === 1) {
              return singleHit(res, false, index)
            } else {
              return multiHit(res, index)
            }
          } else {
            if (res.hits.length === 1) {
              return singleHit(res, false, index)
            } else {
              return singleHit(res, true, index)
            }
          }
        })}
    </>
  };

  return (
    <div className="relative h-full" ref={containerRef}>
      <div
        onClick={() => { setShowPopover(true) }}
        className="h-full"
      >
        <div className="relative h-full flex flex-col justify-center items-start p-[10px]">
          <div className="flex">
            <div className="p-1">
              <img src={isSource ? takeoff : landing} alt="" />
            </div>
            <input
              className={`block w-full bg-transparent text-[14px] placeholder:text-[14px] sm:text-[16px] sm:placeholder:text-[16px] md:text-[18px] md:placeholder:text-[18px] lg:text-[18px] lg:placeholder:text-[18px] placeholder:text-gray-500 p-[5px] border-none outline-none focus:placeholder-neutral-300 placeholder-neutral-800 dark:placeholder-neutral-200`}
              placeholder={placeHolder}
              value={value}
              autoFocus={showPopover}
              onChange={(e) => {
                setValue(e.currentTarget.value);
                // console.log('ceee', e.currentTarget.value.toUpperCase());

                // getDebouncedResults()
                onChange && onChange(e.currentTarget.value);
              }}
              ref={inputRef}
            />
          </div>
          <span className="block mt-0.5 text-sm text-neutral-600 font-normal">
            {isMultiCity
              ?
              (index ?
                (
                  index === 0 ?
                    (isSource ? (!!value ? <span className="line-clamp-1"> {multiSearchFullValues[0]?.origin} </span> : desc) : (!!value ? <span className="line-clamp-1"> {multiSearchFullValues[0]?.destination} </span> : desc))
                    : (isSource ? (!!value ? <span className="line-clamp-1"> {multiSearchFullValues[index]?.origin || ''}</span> : desc) : (!!value ? <span className="line-clamp-1"> {multiSearchFullValues[index]?.destination || ''}</span> : desc))
                )
                : null)
              :
              (!!value ? <span className="line-clamp-1">{fullVal}</span> : desc)
            }
          </span>
          {value && <div className="top-0 absolute right-0">
            <ClearDataButton
              onClick={() => {
                setValue("");
                setFullVal(desc);
                onChange && onChange("");
                import('../../app/actions/searchActions').then(searchAction => dispatch(searchAction.getPlaces({ type: isSource ? 'source' : 'destination', value: "" })))
              }}
            />
          </div>
          }
        </div>
      </div>
      {value && showPopover && (
        <div className="absolute left-0 z-[600] min-w-[300px] bg-white dark:bg-neutral-800 top-full mt-2 py-2 sm:py-3 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
          {value ? renderSearchValue() : null}
        </div>
      )}
    </div>
  );
};

export default LocationInput;







// {/* <span className="block text-neutral-400">
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-4 w-4 sm:h-6 sm:w-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="currentColor"
//               >
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={1.5}
//                   d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
//                 />
//                 <path
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                   strokeWidth={1.5}
//                   d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
//                 />
//               </svg>
//             </span> */}