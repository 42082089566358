// 1 - OneWay, 2 - Return, 3 - Multi Stop, 4 - AdvanceSearch, 5 - Special Return
export enum JourneyType {
    OneWay = '1',
    Return = '2',
    MultiStop = '3',
    AdvanceSearch = '4',
    SpecialReturn = '5',
}


// (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
export enum FlightCabinClass {
    All = '1',
    Economy = "2",
    PremiumEconomy = "3",
    Business = "4",
    PremiumBusiness = "5",
    First = "6"
}