
import { gtag } from 'ga-gtag'

export const gtag_report_conversion = (url) => {
    var callback = function () {
        if (typeof (url) != 'undefined') {
            window.location = url;
        }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-11100115796/rsTgCPO08fgDENSm-awp',
        'event_callback': callback
    });
    return false;
}

export const gtag_report_conversion_callBack = (url) => {
    var callback = function () {
        if (typeof (url) != 'undefined') {
            window.location = url;
        }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-11100115796/nyyzCMyl354YENSm-awp',
        'event_callback': callback
    });
    return false;
}