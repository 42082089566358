import { AppActionTypes } from "app/constants/appActionTypes"

export const openFirstPopup = () => {
    return {
        type: AppActionTypes.OPEN_FIRST_POPUP
    }
}
export const closeFirstPopup = () => {
    return {
        type: AppActionTypes.CLOSE_FIRST_POPUP
    }
}
export const openSecondPopup = () => {
    return {
        type: AppActionTypes.OPEN_SECOND_POPUP
    }
}
export const closeSecondPopup = () => {
    return {
        type: AppActionTypes.CLOSE_SECOND_POPUP
    }
}

export const currencyChanged = (newCurr) => {
    return {
        type: AppActionTypes.CURRENCY_CHANGED,
        payload: newCurr
    }
}
export const forexValue = (val) => {
    return {
        type: AppActionTypes.FOREX_VAL,
        payload: val
    }
}

export const getSettings = (settings) => {
    console.log('ascibasgcaiba', settings);
    return {
        type: AppActionTypes.GET_SETTINGS,
        payload: settings
    }
}