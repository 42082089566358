
const typesenseCred = {
    apiKey: "YmyCfIiZhKJnxrFws2yUgxRUgu6nuhzR",
    searchOnlyKey: "UeLlVwPww9EV39ULsGtmTCRvDyHmy3cI",
    host: "x71o4ecrlpu6n8tjp-1.a1.typesense.net",
    port: 443,
    protocol: "https"
}

export default function typesense_initClient() {
    return new Promise((resolve, reject) => {
        try {
            const Typesense = require('typesense');
            let typesenseClient = new Typesense.Client({
                'nodes': [{
                    'host': typesenseCred.host,
                    'port': typesenseCred.port,
                    'protocol': typesenseCred.protocol
                }],
                'apiKey': typesenseCred.apiKey,
                'connectionTimeoutSeconds': 2
            });
            resolve(typesenseClient);
        } catch (error) {
            console.log('error in initialising typesense client');
            resolve(null);
        }
    })
}
