import moment from "moment"
import { FlightCabinClass, JourneyType } from "utils/enums"
import { searchActionTypes } from "../constants/searchActionTypes"

const initialState = {
    searchData: {
        "EndUserIp": '192.168.10.10',
        "TokenId": '',
        "AdultCount": '1',
        "ChildCount": '0',
        "InfantCount": '0',
        "DirectFlight": 'false',
        "OneStopFlight": 'false',
        "JourneyType": `${JourneyType.Return}`, // 1 - OneWay, 2 - Return, 3 - Multi Stop, 4 - AdvanceSearch, 5 - Special Return
        "PreferredAirlines": null,
        "Segments": [
            {
                "Origin": '',
                "Destination": '',
                "FlightCabinClass": `${FlightCabinClass.Economy}`, // (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
                "PreferredDepartureTime": `${(moment(new Date().setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
                "PreferredArrivalTime": `${(moment(new Date().setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`
            },
            {
                "Origin": '',
                "Destination": '',
                "FlightCabinClass": `${FlightCabinClass.Economy}`, // (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
                "PreferredDepartureTime": `${(moment(new Date().setUTCHours(0, 0, 0, 0)).add({ day: 1 }).toISOString()).slice(0, -5)}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
                "PreferredArrivalTime": `${(moment(new Date().setUTCHours(0, 0, 0, 0)).add({ day: 1 }).toISOString()).slice(0, -5)}`
            },
        ],
        "Sources": null,
    },
    multiSearchFullValues: [] as any,
    timerStarted: false,
    sessionExpired: false,
    isFLightsLoading: false,
    isMobileFlightsLoading: false,

    passengersData: {
        adult: 1,
        child: 0,
        infant: 0,
        senior: 0
    } as any,

    datesRangeData: {
        arrival: null,
        depart: null
    },
    coach: '',
    traceId: null,
}

export const searchReducerFile = (state = initialState, action: any) => {
    switch (action.type) {
        case searchActionTypes.TRACE_ID:
            return { ...state, traceId: action.payload }
        // get search token
        case searchActionTypes.TOKEN_ID:
            return { ...state, searchData: { ...state.searchData, TokenId: action.payload } }
        case searchActionTypes.FLIGHTS_LOADING:
            return { ...state, isFLightsLoading: !state.isFLightsLoading }
        // get journey location details
        case searchActionTypes.PLACE_SELECTED:
            if (action.payload.type === 'source') {
                if (state.searchData.JourneyType === JourneyType.OneWay || state.searchData.JourneyType === JourneyType.MultiStop) {
                    return {
                        ...state, searchData: {
                            ...state.searchData, Segments: [{ ...state.searchData.Segments[0], Origin: action.payload.value }]
                        }
                    }
                } else {
                    return {
                        ...state, searchData: {
                            ...state.searchData, Segments: [{ ...state.searchData.Segments[0], Origin: action.payload.value }, { ...state.searchData.Segments[1], Destination: action.payload.value }]
                        }
                    }
                }
            } else {
                if (state.searchData.JourneyType === JourneyType.OneWay || state.searchData.JourneyType === JourneyType.MultiStop) {
                    return {
                        ...state, searchData: { ...state.searchData, Segments: [{ ...state.searchData.Segments[0], Destination: action.payload.value }] }
                    }
                } else {
                    return {
                        ...state, searchData: { ...state.searchData, Segments: [{ ...state.searchData.Segments[0], Destination: action.payload.value }, { ...state.searchData.Segments[1], Origin: action.payload.value }] }
                    }
                }
            }
        // get journey type
        case searchActionTypes.JOURNEY_TYPE:
            let type = action.payload;
            if (type === JourneyType.OneWay) {
                return {
                    ...state,
                    searchData: {
                        ...state.searchData,
                        JourneyType: action.payload,
                        Segments: [
                            {
                                ...state.searchData.Segments[0],
                            },
                        ]
                    }
                }
            }
            if (type === JourneyType.MultiStop) {
                return {
                    ...state,
                    searchData: {
                        ...state.searchData,
                        JourneyType: action.payload,
                        Segments: [
                            {
                                ...state.searchData.Segments[0],
                            },
                            {
                                ...state.searchData.Segments[0],
                                Origin: state.searchData.Segments[0].Destination,
                                Destination: '',
                                PreferredDepartureTime: state.searchData.Segments[0].PreferredDepartureTime || null,
                                PreferredArrivalTime: state.searchData.Segments[0].PreferredDepartureTime || null,
                            },
                        ]
                    }
                }
            }
            if (type === JourneyType.Return) {
                return {
                    ...state,
                    searchData: {
                        ...state.searchData,
                        JourneyType: action.payload,
                        Segments: [
                            {
                                ...state.searchData.Segments[0],
                            },
                            {
                                ...state.searchData.Segments[0],
                                Origin: state.searchData.Segments[0].Destination,
                                Destination: state.searchData.Segments[0].Origin,
                                PreferredDepartureTime: `${state.searchData.Segments[0].PreferredArrivalTime || ''}`,
                                PreferredArrivalTime: null,
                            },
                        ]
                    }
                }
            } else {
                return { ...state }
            }
        // get flight cabin class
        case searchActionTypes.FLIGHT_CABIN:
            if (state.searchData.JourneyType === JourneyType.Return) {
                return {
                    ...state,
                    searchData: {
                        ...state.searchData,
                        Segments: [{ ...state.searchData.Segments[0], FlightCabinClass: action.payload.cabinClass }, { ...state.searchData.Segments[1], FlightCabinClass: action.payload.cabinClass }]
                    },
                    coach: action.payload.name
                }
            } else if (state.searchData.JourneyType === JourneyType.MultiStop) {
                let newSeg = state.searchData.Segments.map((seg) => { return { ...seg, FlightCabinClass: action.payload.cabinClass } })
                return {
                    ...state,
                    searchData: {
                        ...state.searchData,
                        Segments: newSeg
                    }
                }
            }
            else {
                return {
                    ...state,
                    searchData: {
                        ...state.searchData,
                        Segments: [{ ...state.searchData.Segments[0], FlightCabinClass: action.payload.cabinClass }]
                    },
                    coach: action.payload.name
                }
            }
        // get passenger count
        case searchActionTypes.PASSENGER_COUNT:
            return {
                ...state,
                searchData: {
                    ...state.searchData,
                    AdultCount: `${action.payload.adult + action.payload.senior}`,
                    ChildCount: `${action.payload.child}`,
                    InfantCount: `${action.payload.infant}`
                },
                passengersData: {
                    ...state.passengersData,
                    adult: action.payload.adult,
                    child: action.payload.child,
                    infant: action.payload.infant,
                    senior: action.payload.senior
                }
            }
        // get dates
        case searchActionTypes.GET_DATE:
            let departTime;
            let arrivalTime;
            // console.log('type', action.payload.type);

            // console.log('adare', action.payload.dates);

            if (action.payload?.type !== 'single') {
                departTime = moment(new Date(action.payload.dates.startDate).setUTCHours(0, 0, 0, 0)).toISOString();
                arrivalTime = moment(new Date(action.payload.dates.endDate).setUTCHours(0, 0, 0, 0)).toISOString();
            } else {
                departTime = moment(new Date(action.payload.dates).setUTCHours(0, 0, 0, 0)).toISOString();
                arrivalTime = null;
            }
            if (state.searchData.JourneyType === JourneyType.Return) {
                return {
                    ...state,
                    searchData: {
                        ...state.searchData,
                        Segments: [
                            {
                                ...state.searchData.Segments[0],
                                PreferredDepartureTime: `${departTime.slice(0, -5)}`,
                                PreferredArrivalTime: `${departTime?.slice(0, -5)}`,
                            },
                            {
                                ...state.searchData.Segments[0],
                                Origin: state.searchData.Segments[0].Destination,
                                Destination: state.searchData.Segments[0].Origin,
                                PreferredDepartureTime: `${arrivalTime?.slice(0, -5)}`,
                                PreferredArrivalTime: `${arrivalTime?.slice(0, -5)}`,
                            },
                        ],
                        Sources: null,
                    },
                    datesRangeData: { ...state.datesRangeData, arrival: action.payload?.dates?.startDate, depart: action.payload?.dates?.endDate }
                }
            }
            if (state.searchData.JourneyType === JourneyType.MultiStop) {
                return { ...state, searchData: { ...state.searchData, Segments: [...state.searchData.Segments] } }
            }
            return {
                ...state,
                searchData: {
                    ...state.searchData,
                    Segments: [{
                        ...state.searchData.Segments[0],
                        PreferredDepartureTime: `${departTime.slice(0, -5)}`,
                        PreferredArrivalTime: `${departTime.slice(0, -5)}`,
                    }]
                },
                datesRangeData: { ...state.datesRangeData, arrival: action.payload?.dates?.startDate, depart: action.payload?.dates?.endDate }
            }

        case searchActionTypes.START_TIMER:
            return {
                ...state,
                timerStarted: true
            }
        case searchActionTypes.END_TIMER:
            return {
                ...state,
                timerStarted: false
            }
        case searchActionTypes.SESSION_EXPIRED:
            return {
                ...state,
                sessionExpired: true
            }
        case searchActionTypes.REFRESH_RESULTS:
            return {
                ...state,
                showItineraryReload: action.payload
            }
        case searchActionTypes.SESSION_STARTED:
            return {
                ...state,
                sessionExpired: false
            }

        case searchActionTypes.SEGMENT_SELECTED:
            return { ...state, searchData: { ...state.searchData, Segments: action.payload } }
        case searchActionTypes.MULTISEARCH_VALUES:
            return { ...state, multiSearchFullValues: action.payload }
        case searchActionTypes.DATES_SEGMENT_SELECTED:
            return { ...state, searchData: { ...state.searchData, Segments: action.payload } }
        case searchActionTypes.SEGMENT_ADDED:
            return {
                ...state,
                searchData: {
                    ...state.searchData,
                    Segments: [...state.searchData.Segments, {
                        "Origin": state.searchData.Segments[state.searchData.Segments.length - 1].Destination,
                        "Destination": '',
                        "FlightCabinClass": `${FlightCabinClass.All}`, // (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
                        "PreferredDepartureTime": `${(moment(new Date().setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
                        "PreferredArrivalTime": `${(moment(new Date().setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`
                    }]
                },
                multiSearchFullValues: [...state.multiSearchFullValues, {
                    origin: state.multiSearchFullValues[state.multiSearchFullValues.length - 1]?.destination,
                    destination: ''
                }]
            }
        case searchActionTypes.DELETE_SEGMENTS:
            return { ...state, searchData: { ...state.searchData, Segments: action.payload } }

        case searchActionTypes.FLIP_DATA:
            if (state.searchData.JourneyType === JourneyType.Return) {
                return {
                    ...state, searchData: {
                        ...state.searchData, Segments: [
                            {
                                ...state.searchData.Segments[0], Origin: state.searchData.Segments[0].Destination, Destination: state.searchData.Segments[0].Origin
                            },
                            { ...state.searchData.Segments[1], Origin: state.searchData.Segments[1].Destination, Destination: state.searchData.Segments[1].Origin }
                        ]
                    }
                }
            }
            return {
                ...state, searchData: { ...state.searchData, Segments: [{ ...state.searchData.Segments[0], Origin: state.searchData.Segments[0].Destination, Destination: state.searchData.Segments[0].Origin },] }
            }
        case searchActionTypes.DELETED_FULLNAMES:
            return { ...state, multiSearchFullValues: action.payload }

        case searchActionTypes.MOBILE_FLIGHT_LOADING_TRUE:
            return { ...state, isMobileFlightsLoading: true }
        case searchActionTypes.MOBILE_FLIGHT_LOADING_FALSE:
            return { ...state, isMobileFlightsLoading: false }

        case searchActionTypes.TOOGLE_DIRECT_FLIGHTS:
            return { ...state, searchData: { ...state.searchData, DirectFlight: state.searchData.DirectFlight === "true" ? "false" : "true" } }
        case searchActionTypes.GET_PREFERRED_AIRLINES:
            return { ...state, searchData: { ...state.searchData, PreferredAirlines: action?.payload } }
        default:
            return state
    }
}