import { gtag } from 'ga-gtag'

export const callNowButtonTags = (url) => {
    var callback = function () {
        if (typeof (url) != 'undefined') {
            window.location = url;
        }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-11100115796/T_lyCMvktI0YENSm-awp',
        'transaction_id': '',
        'event_callback': callback
    });
    return false;

}