import { auth, db } from 'config/firebase-config';
import { signInWithEmailAndPassword } from 'firebase/auth'
import { doc, getDoc, updateDoc } from 'firebase/firestore';

export const signInWithEmail = async (data: any) => {
    const { email, password } = data
    return await signInWithEmailAndPassword(auth, email, password)
        .then((res) => {
            const uid = res.user.uid
            try {
                localStorage.setItem('@auth-token', uid)
            } catch (error) {
                console.log(error);
            }
            return { uid: res.user.uid, message: '' }
        })
        .catch(error => {
            if (error.toString().includes('user')) {
                return { uid: error, message: 'Incorrect Email' }
            } else {
                return { uid: error, message: "Incorrect Password" }
            }
        })
}

export const getUser = async () => {
    const uid = localStorage.getItem('@auth-token')

    if (uid) {
        const userRef = doc(db, 'Users', uid);
        const docs = await getDoc(userRef);
        if (!docs.exists) {
            return null
        } else {
            let user = docs.data();
            if (user?.country == 'unavailable') {
                const res = await fetch('https://geolocation-db.com/json/')
                const data = await res.json();
                await updateDoc(doc(db, "Users", uid), { country: data.country_name })
            }
            return user;
        }
    } else {
        return null
    }
}