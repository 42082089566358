import { AppActionTypes } from "app/constants/appActionTypes"

const initialState = {
    isFirstPopUpOpen: true,
    isSecondPopUpOpen: false,
    currency: null,
    settings: null,
    forexValue: 1
}

export const appReducer = (state = initialState, action) => {
    switch (action.type) {
        // get search token
        case AppActionTypes.OPEN_FIRST_POPUP:
            return { ...state, isFirstPopUpOpen: true }
        case AppActionTypes.CLOSE_FIRST_POPUP:
            return { ...state, isFirstPopUpOpen: false }
        case AppActionTypes.OPEN_SECOND_POPUP:
            return { ...state, isSecondPopUpOpen: true }
        case AppActionTypes.CLOSE_SECOND_POPUP:
            return { ...state, isSecondPopUpOpen: false }
        case AppActionTypes.CURRENCY_CHANGED:
            return { ...state, currency: action.payload }
        case AppActionTypes.FOREX_VAL:
            return { ...state, forexValue: action.payload }
        case AppActionTypes.GET_SETTINGS:
            return { ...state, settings: action.payload }
        // get journey location details
        default:
            return state
    }
}


