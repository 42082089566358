import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import "./flightsearchpopup.css"
import { RiPlaneFill } from "react-icons/ri"
import { useSelector } from 'react-redux';
import { IoCallSharp } from "react-icons/io5";
import OutsideClickHandler from "utils/OutsideClickHandler";
import { gtag_report_conversion } from "googleAnalytics/googleAds";
import { callNowButtonTags } from "googleAnalytics/callNowButtonTags";

interface PopupProps {
    onClose?: any,
}
const FlightSearchPopup2 = ({ onClose }: PopupProps) => {
    const { userLocation } = useSelector((state: any) => state.userReducer)
    const { currency } = useSelector((state: any) => state.appReducer)

    return (
        <OutsideClickHandler onClick={onClose}>
            <div className="relative bg-white overflow-hidden relative rounded-lg min-w-[300px] max-w-[500px] p-[30px] flex flex-col items-center">
                {/* close button */}
                <div onClick={onClose} className="cursor-pointer absolute top-[10px] right-[10px] text-[20px]"><AiOutlineClose /></div>
                <div className="flex items-start justify-center text-lg flex-col justify-center items-center">
                    <div className="relative text-6xl px-[10px] text-[#0A4293] flex justify-center items-center h-[100px] overflow-hidden w-full"><RiPlaneFill className="absolute text-6xl text-[#F70F36] airplane-animation" /></div>
                    <div className=" font-semibold text-gray-800 text-center text-[15px]">Unlock Great Savings with Unpublished Flight Deals !</div>
                </div>
                {currency?.country !== "IN" && <div className=" font-medium text-gray-800 text-center text-sm">Connect with Travel Expert NOW !!!</div>}
                {/* <div onClick={onClose} className="my-[5px] cursor-pointer bg-orange-500 rounded-md py-[16px] px-[40px] text-white button text-lg font-semibold">Flights Packages</div> */}

                {currency?.country !== "IN" &&
                    <div className="m-[10px]">
                        <a
                            onClick={() => {
                                gtag_report_conversion(`tel:${currency?.country === "IN" ? "+91 11 420 77777 " : '+1 315 538 6030'}`)
                                callNowButtonTags(`tel:${currency?.country === "IN" ? "+91 11 420 77777 " : '+1 315 538 6030'}`)
                            }
                            }
                            href={`tel:${currency?.country === "IN" ? "+91 011 420 77777 " : '+1 315 538 6030'}`} className="flex items-center my-[5px] cursor-pointer bg-[#F70F36] rounded-md py-[10px] px-[35px] text-white button">
                            <IoCallSharp /> {currency?.country === "IN" ? "011 42077777" : "+1 315 538 6030"}
                        </a>
                    </div>
                }
                <div className="py-[5px] text-[12px] flex flex-col justify-center items-center">
                    Searching for Cheap Flight Deals...
                </div>
                <div className="demo-container">
                    <div className="progress-bar">
                        <div className="progress-bar-value"></div>
                    </div>
                </div>


            </div>
        </OutsideClickHandler>
    );
}
export default FlightSearchPopup2;