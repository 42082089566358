import React from 'react'
import { Link } from 'react-router-dom'

const PaymentFail = () => {
    return (
        <div className='min-h-[80vh] flex justify-center items-center flex-col gap-2'>
            <h1 className='font-bold text-3xl text-red-500'>Payment Failed!</h1>
            <p className='text-lg text-black'>Due to some reasons your payment could not be processed!</p>
            <Link to="/" className='bg-primary-6000 py-2 px-5 rounded-md text-white'>Go to home</Link>
        </div>
    )
}

export default PaymentFail