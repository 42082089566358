import React from "react";
export interface LogoProps {
  logo?: string;
}
const LogoSvgLight: React.FC<LogoProps> = ({ logo }) => {
  return (
    <img src={logo} alt="" className="bg-transparent" />
  );
};

export default LogoSvgLight;
