export const flightActionTypes = {
    FLIGHT_FOUND: "FLIGHT_FOUND",
    // TRACE_ID: 'TRACE_ID',
    FARE_RULE: "FARE_RULE",
    FARE_QUOTE: "FARE_QUOTE",
    RETURN_FLIGHT_SELECTED: "RETURN_FLIGHT_SELECTED",
    PASSENGERS: "PASSENGERS",
    REMOVE_SELECTED_FLIGHTS: 'REMOVE_SELECTED_FLIGHTS',
    PASSENGER_DETAILS: 'PASSENGER_DETAILS',
    CHEAPEST_FLIGHT: 'CHEAPEST_FLIGHT',
    SHORTEST_FLIGHT: 'SHORTEST_FLIGHT',
    RESET_FLIGHTS: "RESET_FLIGHTS",
    RESET_FAREQUOTE_AND_FARERULE: "RESET_FAREQUOTE_AND_FARERULE",
    GET_SEATS: 'GET_SEATS'
}