// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useEffect, useState, lazy, Suspense } from "react";
// import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import { useLocation, useSearchParams } from "react-router-dom";
import { searchFlights } from "services/flightRequests";
import { useDispatch, useSelector } from "react-redux";
import Loading from "shared/Loading/Loading";
import moment from "moment";
import { getTokenId } from "services/firebaseQueries";
import { Backdrop } from "@mui/material";
import FlightSearchPopup from "components/FlightSearchPopup/FlightSearchPopup";
import FlightSearchPopup2 from "components/FlightSearchPopup/FlightSearchPopup2";
import { flightsFound } from "../../app/actions/flightActions";
// import MyTimer from "components/Timer/Timer";
import inr from "../../images/currency/India.png"
import usd from "../../images/currency/US.png"
import cad from "../../images/currency/Canada.png"
import { getUrlSearchParams } from "utils/urlSearchParams";
import { WebPage } from 'schema-dts';
import { JsonLd } from "react-schemaorg";

import useMediaQuery from "@mui/material/useMediaQuery";
export interface ListingFlightsPageProps {
  className?: string;
}
const currencyList = [
  {
    name: 'USD',
    symbol: '$',
    country: "US",
    icon: usd,
  },
  {
    name: 'CAD',
    symbol: 'C$',
    country: "CA",
    icon: cad,
  },
  {
    name: 'INR',
    symbol: '₹',
    country: "IN",
    icon: inr,
  }
]
const SectionGridFilterCard = lazy(() => import("./SectionGridFilterCard"))

const ListingFlightsPage: FC<ListingFlightsPageProps> = ({
  className = "",
}) => {
  var schema: any = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Flights - AeroTrav",
    "description": "Find and book flights with AeroTrav. We offer a wide range of flight options, including one-way, round-trip, and multi-city itineraries. Search and compare prices from multiple airlines to find the best deals on your next flight.",
    "url": "https://aerotrav.com/flights",
  }

  const [urlSearchParams]: any = useSearchParams()
  const matches = useMediaQuery('(min-width:600px)');
  const time = new Date();
  time.setSeconds(time.getSeconds() + 900); // 10 minutes timer
  const [fullVal, setFullVal] = useState<String>("");

  const itemsPerPage = 20;

  const flightActions = import('../../app/actions/flightActions');
  const searchActions = import('../../app/actions/searchActions');
  const { sessionExpired } = useSelector((red: any) => red.searchReducer);
  const { flights } = useSelector((red: any) => red.flightsReducer);
  const { currency } = useSelector((red: any) => red.appReducer);
  const [records, setrecords] = useState(itemsPerPage);
  const [isPopUpOpen, setPopUpOpen] = useState(false);
  const [isPopUp2Open, setPopUp2Open] = useState(false);


  const dispatch = useDispatch();
  const [isFLightsLoading, setIsFlightsLoading] = useState(false)

  const getFlights = async () => {
    let searchQuery: any;
    flightActions.then(action => dispatch(action.resetFlights()))
    setIsFlightsLoading(true)
    setrecords(itemsPerPage)
    let { IN, CA } = await getTokenId();
    let curren = currencyList.filter((curr: any) => curr.name === urlSearchParams.get('cur'))[0]
    searchActions.then(action => dispatch(action.getTokenId(urlSearchParams.get('cur') === "INR" ? IN : IN)))
    // searchActions.then(action => dispatch(action.getTokenId(urlSearchParams.get('curr') === "INR" ? IN : CA)))

    import('../../app/actions/appActions').then(action => dispatch(action.currencyChanged(curren)))

    if (urlSearchParams.get('triptype') === '1') {
      searchActions.then(action => dispatch(action.getJourneyType(urlSearchParams.get('triptype'))))
      searchActions.then(action => dispatch(action.getPlaces({ type: 'source', value: urlSearchParams.get('from') })))
      searchActions.then(action => dispatch(action.getPlaces({ type: 'destination', value: urlSearchParams.get('to') })))
      searchActions.then(action => dispatch(action.getCabinClass(urlSearchParams.get('class'), 'cabin')))
      searchActions.then(action => dispatch(action.getPassengerCount({ adult: parseInt(urlSearchParams.get('adt')), senior: parseInt(urlSearchParams.get('sen')), child: parseInt(urlSearchParams.get('chd')), infant: parseInt(urlSearchParams.get('infs')) })))
      // let date = moment(new Date(`${urlSearchParams.get('depart')}`))
      let date = moment(`${urlSearchParams.get('depart')} 00:00:00`);
      // console.log({ date });
      // console.log(moment(date).format("YYYY-MM-DDT00:00:00"))

      searchActions.then(action => dispatch(action.getDates(date, 'single')))
      searchQuery = {
        "EndUserIp": '192.168.10.10',
        // "TokenId": `${urlSearchParams.get('curr') === "IN" ? IN : CA}`,
        "TokenId": IN,
        "AdultCount": `${parseInt(urlSearchParams.get('adt')) + parseInt(urlSearchParams.get('sen'))}`,
        "ChildCount": urlSearchParams.get('chd'),
        "InfantCount": urlSearchParams.get('infs'),
        "DirectFlight": urlSearchParams.get('direct'),
        "OneStopFlight": 'false',
        "JourneyType": `${urlSearchParams.get('triptype')}`, // 1 - OneWay, 2 - Return, 3 - Multi Stop, 4 - AdvanceSearch, 5 - Special Return
        "PreferredAirlines": urlSearchParams.get('pa') ? urlSearchParams.get('pa') !== "null" ? [urlSearchParams.get('pa')] : null : null,
        "Segments": [
          {
            "Origin": urlSearchParams.get('from'),
            "Destination": urlSearchParams.get('to'),
            "FlightCabinClass": `${urlSearchParams.get('class')}`, // (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
            // "PreferredDepartureTime": `${(moment(new Date(date.toDate()).setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
            // "PreferredArrivalTime": `${(moment(new Date(date.toDate()).setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`
            "PreferredDepartureTime": `${moment(date).format("YYYY-MM-DDT00:00:00")}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
            "PreferredArrivalTime": `${moment(date).format("YYYY-MM-DDT00:00:00")}`
          }
        ],
        "Sources": null,
      }
    }
    if (urlSearchParams.get('triptype') === "2") {
      searchActions.then(action => dispatch(action.getJourneyType(urlSearchParams.get('triptype'))))
      searchActions.then(action => dispatch(action.getPlaces({ type: 'source', value: urlSearchParams.get('from1') })))
      searchActions.then(action => dispatch(action.getPlaces({ type: 'destination', value: urlSearchParams.get('to1') })))
      searchActions.then(action => dispatch(action.getCabinClass(urlSearchParams.get('class'), 'cabin')))
      searchActions.then(action => dispatch(action.getPassengerCount({ adult: parseInt(urlSearchParams.get('adt')), senior: parseInt(urlSearchParams.get('sen')), child: parseInt(urlSearchParams.get('chd')), infant: parseInt(urlSearchParams.get('infs')) })))
      let date1 = moment(`${urlSearchParams.get('depart')} 00:00:00`)
      let date2 = moment(`${urlSearchParams.get('return')} 00:00:00`)
      searchActions.then(action => dispatch(action.getDates({ startDate: date1, endDate: date2 })))
      searchQuery = {
        "EndUserIp": '192.168.10.10',
        // "TokenId": `${urlSearchParams.get('curr') === "IN" ? IN : CA}`,
        "TokenId": IN,
        "AdultCount": `${parseInt(urlSearchParams.get('adt')) + parseInt(urlSearchParams.get('sen'))}`,
        "ChildCount": urlSearchParams.get('chd'),
        "InfantCount": urlSearchParams.get('infs'),
        "DirectFlight": urlSearchParams.get('direct'),
        "OneStopFlight": 'false',
        "JourneyType": `${urlSearchParams.get('triptype')}`, // 1 - OneWay, 2 - Return, 3 - Multi Stop, 4 - AdvanceSearch, 5 - Special Return
        "PreferredAirlines": urlSearchParams.get('pa') ? urlSearchParams.get('pa') !== "null" ? [urlSearchParams.get('pa')] : null : null,
        "Segments": [
          {
            "Origin": urlSearchParams.get('from1'),
            "Destination": urlSearchParams.get('to1'),
            "FlightCabinClass": `${urlSearchParams.get('class')}`, // (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
            "PreferredDepartureTime": `${moment(date1).format("YYYY-MM-DDT00:00:00")}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
            "PreferredArrivalTime": `${moment(date1).format("YYYY-MM-DDT00:00:00")}`
          },
          {
            "Origin": urlSearchParams.get('from2'),
            "Destination": urlSearchParams.get('to2'),
            "FlightCabinClass": `${urlSearchParams.get('class')}`, // (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
            "PreferredDepartureTime": `${moment(date2).format("YYYY-MM-DDT00:00:00")}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
            "PreferredArrivalTime": `${moment(date2).format("YYYY-MM-DDT00:00:00")}`
          },
        ],
        "Sources": null,
      }
    }
    if (urlSearchParams.get('triptype') === '3') {
      let length = parseInt(urlSearchParams.get('seglen'));
      let segments: any = [];
      for (var i = 0; i < length; i++) {
        let date = moment(new Date(`${urlSearchParams.get(`depart${i + 1}`)} 08:00:00`).setUTCHours(0, 0, 0, 0))
        segments.push({
          "Origin": urlSearchParams.get(`from${i + 1}`),
          "Destination": urlSearchParams.get(`to${i + 1}`),
          "FlightCabinClass": `${urlSearchParams.get('class')}`, // (1 for All, 2 for Economy, 3 for PremiumEconomy, 4 for Business, 5 for PremiumBusiness, 6 for First)
          "PreferredDepartureTime": `${(moment(new Date(date.toDate()).setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`, //Format: yyyy-MM-ddTHH:mm:ss (2015-08-10T00:00:00 for any time 2015-08-10T08:00:00 for Morning Flights 2015-08-10T14:00:00 for Afternoon Flights 2015-08-10T19:00:00 for Evening Flights 2015-08-10T01:00:00 for Night Flights)
          "PreferredArrivalTime": `${(moment(new Date(date.toDate()).setUTCHours(0, 0, 0, 0)).toISOString()).slice(0, -5)}`
        })
      }
      searchActions.then(action => dispatch(action.getJourneyType(urlSearchParams.get('triptype'))))
      searchActions.then(action => dispatch(action.getPassengerCount({ adult: parseInt(urlSearchParams.get('adt')), senior: parseInt(urlSearchParams.get('sen')), child: parseInt(urlSearchParams.get('chd')), infant: parseInt(urlSearchParams.get('infs')) })))
      searchActions.then(action => dispatch(action.getSegment(segments)))

      searchQuery = {
        "EndUserIp": '192.168.10.10',
        // "TokenId": `${urlSearchParams.get('curr') === "IN" ? IN : CA}`,
        "TokenId": IN,
        "AdultCount": `${parseInt(urlSearchParams.get('adt')) + parseInt(urlSearchParams.get('sen'))}`,
        "ChildCount": urlSearchParams.get('chd'),
        "InfantCount": urlSearchParams.get('infs'),
        "DirectFlight": urlSearchParams.get('direct'),
        "OneStopFlight": 'false',
        "JourneyType": `${urlSearchParams.get('triptype')}`, // 1 - OneWay, 2 - Return, 3 - Multi Stop, 4 - AdvanceSearch, 5 - Special Return
        "PreferredAirlines": urlSearchParams.get('pa') ? urlSearchParams.get('pa') !== "null" ? [urlSearchParams.get('pa')] : null : null,
        "Segments": segments,
        "Sources": null,
      }

    }

    searchActions.then(actions => dispatch(actions.endTimer()))
    // let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
    // let countryData = await data.json();
    const res = await searchFlights({ data: searchQuery, country: curren?.country || currency?.country });

    if (res?.status === 200) {
      searchActions.then(actions => dispatch(actions.getTraceId(res?.traceId)))
      flightActions.then(actions => dispatch(actions.flightsFound({ flights: res?.results, error: res?.error })))
      setIsFlightsLoading(false);
      searchActions.then(actions => dispatch(actions.startTimer()))
      console.log('searchEnded')
    }
    else if (res?.status === 500) {
      setIsFlightsLoading(false);
      flightActions.then(actions => dispatch(actions.flightsFound({ flights: [], error: 'No Results Found' })))
    }
    else {
      searchActions.then(actions => dispatch(actions.getTraceId(res?.traceId)))
      flightActions.then(actions => dispatch(actions.flightsFound({ flights: res?.results, error: res?.error })))
      setIsFlightsLoading(false)
    }
  }


  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])


  useEffect(() => {
    if (sessionExpired === false) {
      if (urlSearchParams.get('triptype')) {
        flightActions.then(action => dispatch(action.resetFlights()))
        getFlights()
      }
    }
  }, [sessionExpired, urlSearchParams])


  useEffect(() => {
    if (isFLightsLoading) {
      setPopUpOpen(false);
      setPopUp2Open(true);
    }
    else {
      if (currency?.country !== "IN") {
        setPopUpOpen(true);
      }
      setPopUp2Open(false);
    }
  }, [isFLightsLoading])

  const matchesXS = useMediaQuery('(max-width:550px)');


  return (
    <div
      className={`nc-ListingFlightsPage relative overflow-hidden ${className} bg-neutral-50`}
      data-nc-id="ListingFlightsPage"
    >
      <Helmet>
        <title>Flight offers: Find the best flight deals online | AeroTrav</title>
        <meta name="description" content="Find the best flight offers and deals to all your favourite destinations, and compare prices from 1000s of airlines &amp; travel providers. Book online today" />
        <meta name="keywords" content="flight deals, flight offers, cheapest flight, air ticket booking, online flight ticket booking low fare flight" />
      </Helmet>
      {/* <BgGlassmorphism /> */}
      <JsonLd<WebPage>
        item={schema}
      />
      <div className="container relative">
        <Backdrop
          style={{ zIndex: '50' }}
          open={isPopUpOpen && (!isFLightsLoading) && flights && flights.length !== 0
            //  && flights.length !== 0
          }// !isFlightsLoading
        >
          <FlightSearchPopup onClose={() => setPopUpOpen(false)} />
        </Backdrop>
        <Backdrop
          style={{ zIndex: '50' }}
          open={isPopUp2Open && (isFLightsLoading)
            //  && flights.length !== 0
          }// !isFlightsLoading
        >
          <FlightSearchPopup2 onClose={() => setPopUp2Open(false)} />
        </Backdrop>

        {/* SECTION HERO */}
        <SectionHeroArchivePage
          currentPage="Flights"
          currentTab="Flights"
          setIsFlightsLoading={setIsFlightsLoading}
          listingType={
            <>
              <i className="text-2xl las la-plane-departure"></i>
              <span className="ml-2.5">1599 flights</span>
            </>
          }
          className={`pt-0 ${matchesXS ? "pb-5" : "pb-12"}  lg:pb-6 lg:pt-0 z-20 px-5 rounded-b-2xl`}
        />

        {/* SECTION */}
        <Suspense fallback={<Loading />}>
          <SectionGridFilterCard className="pb-24 lg:pb-28 z-0 mt-4" isFlightsLoading={isFLightsLoading} records={records} setrecords={setrecords} itemsPerPage={itemsPerPage} />
        </Suspense>
      </div>
    </div>
  );
};

export default ListingFlightsPage;
