// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import React, { FC, useEffect, useState, lazy, Suspense } from "react";
// import SectionGridFilterCard from "./SectionGridFilterCard";
import { Helmet } from "react-helmet";
import HomePageContent from "./HomePageContent";
import "./styles/flightsPage.css"
import Destinations from "components/Destinations/Destinations";
// import MyTimer from "components/Timer/Timer";
import Vis from "../../images/airlines/Vistara.webp"
import AirIndia from "../../images/airlines/AirIndia.webp"
import EK from "../../images/airlines/EK.webp"
import AC from "../../images/airlines/AC.webp"
import Indigo from "../../images/airlines/Indigo.webp"
import PopularSearches from "./PopularSearches";
import TopDestinations from "./TopDestinations"
import FlightsFeatures from "./FlightsFeatures";

export interface FlightsPageProps {
    className?: string;
}

const FlightsPage: FC<FlightsPageProps> = ({
    className = "",
}) => {



    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    return (
        <div
            className={`nc-ListingFlightsPage relative overflow-hidden ${className}`}
            data-nc-id="ListingFlightsPage"
        >
            <Helmet>
                <title>Flight offers: Find the best flight deals online | AeroTrav</title>
                <meta name="description" content="Find the best flight offers and deals to all your favourite destinations, and compare prices from 1000s of airlines &amp; travel providers. Book online today" />
                <meta name="keywords" content="flight deals, flight offers, cheapest flight, air ticket booking, online flight ticket booking low fare flight" />
            </Helmet>
            {/* <BgGlassmorphism /> */}

            <div className="relative min-h-screen ">
                {/* SECTION HERO */}
                <div className="bg-image flex flex-col justify-center z-[9] relative">
                    <div className='relative flex items-center w-full justify-center h-full'>
                        <h1 className='md-2 mt-[10px] mx-[5px] text-center text-xl sm:text-3xl lg:text-4xl xl:text-5xl text-white mb-2 sm:mb-6 md:mb-6 lg:mb-8 xl:mb-10 font-bold drop-shadow-[0_5px_5px_rgba(0,0,0,0.8)]'>Travel the World with 400+ Airlines</h1>
                    </div>
                    <SectionHeroArchivePage
                        currentPage="Flights"
                        currentTab="Flights"
                        isFlightsHome
                        listingType={
                            <>
                                <i className="text-2xl las la-plane-departure"></i>
                                <span className="ml-2.5">1599 flights</span>
                            </>
                        }
                        className="pt-0 pb-12 lg:pb-6 lg:pt-0 z-20 container "
                    />
                </div>
                <div className="p-[20px]"></div>
                {/* <Destinations /> */}
                {/* <PopularSearches/>*/}
                {/* <div className="z-[8] relative">
                    <TopDestinations />
                </div> */}
                <FlightsFeatures />
                <HomePageContent />
                {/* popular airline */}
                <div className="w-[93%] m-auto flex flex-wrap items-center justify-center mb-[20px]">
                    <div className="p-[30px] flex flex-col items-center">
                        <div className="text-3xl font-bold "> Popular Airlines</div>
                        {/* <div className="text-sm font-normal ">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ex, iste?</div> */}
                    </div>
                    <div className=" flex flex-wrap justify-center items-center">
                        <img className="p-[5px] sm:p-[10px] md:p-[15px] lg:p-[30px]" src={Vis} alt="" />
                        <img className="p-[5px] sm:p-[10px] md:p-[15px] lg:p-[30px]" src={Indigo} alt="" />
                        <img className="p-[5px] sm:p-[10px] md:p-[15px] lg:p-[30px]" src={AirIndia} alt="" />
                        <img className="p-[5px] sm:p-[10px] md:p-[15px] lg:p-[30px]" src={EK} alt="" />
                        <img className="p-[5px] sm:p-[10px] md:p-[15px] lg:p-[30px]" src={AC} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlightsPage;
