import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import { useSelector } from "react-redux";
import "./navigation.css"

function Navigation() {
  const { user } = useSelector((state: any) => state.userReducer)
  return (
    <ul className="flex navigation justify-left lg:space-x-1 relative flex-1">
      {NAVIGATION_DEMO.map((item) => {
        if (item.name !== 'Login/Signup') {
          if (user) {
            return <NavigationItem key={item.id} menuItem={item} />
          } else {
            if (item.name === 'Home') return <NavigationItem key={item.id} menuItem={item} home />
            if (item.name !== 'My Bookings') return <NavigationItem key={item.id} menuItem={item} />
          }
        }
      })}
      {/* // (
        //   <NavigationItem key={item.id} menuItem={item} />
        // ))} */}
    </ul>
  );
}

export default React.memo(Navigation);
