import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";
import { useSelector } from "react-redux";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
  socials?: SocialType[];
}

const socialsDemo: SocialType[] = [
  { name: "Facebook", icon: "lab la-facebook-square text-3xl", href: "https://www.facebook.com/aerotrav/",indiaHref:"https://www.facebook.com/aerotravin?mibextid=LQQJ4d" },
  { name: "Instagram", icon: "lab la-instagram text-3xl", href: "https://www.instagram.com/aerotravus/",indiaHref:"https://instagram.com/aerotravin?igshid=YmMyMTA2M2Y=" },
  { name: "Linkedin", icon: "lab la-linkedin text-3xl", href: "https://www.linkedin.com/company/aerotrav",indiaHref:"https://www.linkedin.com/company/aerotrav" },
  { name: "Twitter", icon: "lab la-twitter text-3xl", href: "https://twitter.com/AeroTrav",indiaHref:"https://twitter.com/aerotravin?s=21&t=2uZXxDSSC06JEGklt7BJSw" },
];

const SocialsList: FC<SocialsListProps> = ({
  className = "",
  itemClass = "block",
  socials = socialsDemo,
}) => {
  const {currency} =useSelector((state:any)=>state.appReducer)
  return (
    <div className="flex gap-2">
      {
        socials.map((item, i) => 
          {
          if(currency?.country==="IN"&&item.indiaHref==="")
          return <></>
          return <a
            key={i}
            className="flex"
            href={currency?.country==="IN"?item.indiaHref:item.href}
            target="_blank"
            rel="noopener noreferrer"
            title={item.name}
          >
            <i className={item.icon} ></i>
          </a>}
        )
      }
    </div >
  );
};

export default SocialsList;
