import FlightSearchForm from 'components/HeroSearchForm/FlightSearchForm'
import React, { FC } from 'react'
import "./hero.css"
export interface HeroProps {
    className?: any
}

const Hero: FC<HeroProps> = ({ className }) => {
    return (
        <div className={`bg-image-hero min-h-[580px] sm:min-h-[500px] relative ${className} z-30 flex flex-col justify-center items-center`}>
            <div className='relative flex items-center w-full justify-center h-full'>
                <h1 className='text-center text-xl sm:text-3xl lg:text-4xl xl:text-5xl text-white my-[5px] mx-[5px] sm:md-2 md:mb-4 lg:mb-6 xl:mb-8 font-bold drop-shadow-[0_5px_5px_rgba(0,0,0,0.8)]'>Travel the World with 400+ Airlines</h1>
            </div>
            {/* <div className='w-[80%] mx-auto h-full 2xl:pt-[100px] xl:pt-[80px] lg:pt-[60px] md:pt-[40px] sm:pt-[20px] pt-[20px] z-10'>
                <h1 className='sm:text-5xl text-4xl font-bold text-primary-6000'>World is Small,</h1>
                <h1 className='sm:text-5xl text-3xl font-bold mt-2'>KEEP FLYING WITH US!</h1>
            </div> */}
            {/* <img src={Aero} className="min-w-full max-w-[700px] sm:max-w-fit aspect-auto" alt="" /> */}
            <div className="w-full relative">
                <FlightSearchForm />
            </div>
            {/* <div className="relative translate-y-[-90px] sm:translate-y-[-130px] xl:translate-y-[-160px]">
                <FlightSearchForm />
            </div> */}
            {/* <img src={Pluses} className="absolute right-[40px] translate-y-[50%] sm:z-0 z-[-1]" alt='pulses' /> */}
        </div>
    )
}

export default Hero