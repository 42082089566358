import moment from "moment"

export const getUrlSearchParams = (searchData, senior, currency) => {
    let preferredAirline = searchData?.PreferredAirlines;
    if (searchData.JourneyType === '1') {
        let temp = searchData.Segments[0].PreferredDepartureTime.split('T')[0]
        let date = moment(new Date(temp)).format('YYYY-MM-DD')
        if (preferredAirline) {
            return `?cur=${currency?.name}&triptype=${searchData.JourneyType}&from=${searchData.Segments[0].Origin}&to=${searchData.Segments[0].Destination}&depart=${date}&class=${searchData.Segments[0].FlightCabinClass}&adt=${parseInt(searchData.AdultCount) - parseInt(senior)}&chd=${searchData.ChildCount}&infs=${searchData.InfantCount}&sen=${senior}&infl=0&${preferredAirline ? `pa=${preferredAirline[0]}` : `pa=null`}&direct=${searchData?.DirectFlight}`
        } else {
            return `?cur=${currency?.name}&triptype=${searchData.JourneyType}&from=${searchData.Segments[0].Origin}&to=${searchData.Segments[0].Destination}&depart=${date}&class=${searchData.Segments[0].FlightCabinClass}&adt=${parseInt(searchData.AdultCount) - parseInt(senior)}&chd=${searchData.ChildCount}&infs=${searchData.InfantCount}&sen=${senior}&infl=0&direct=${searchData?.DirectFlight}`
        }
    }

    if (searchData.JourneyType === '2') {
        let temp1 = searchData.Segments[0].PreferredDepartureTime.split('T')[0]
        let DT = moment(new Date(temp1)).format('YYYY-MM-DD')
        let temp2 = searchData?.Segments[1]?.PreferredDepartureTime.split('T')[0]
        let RT = moment(new Date(temp2)).format('YYYY-MM-DD')

        if (preferredAirline) {
            return `?cur=${currency?.name}&triptype=${searchData.JourneyType}&from1=${searchData.Segments[0].Origin}&to1=${searchData.Segments[0].Destination}&depart=${DT}&from2=${searchData.Segments[1].Origin}&to2=${searchData.Segments[1].Destination}&return=${RT}&class=${searchData.Segments[0].FlightCabinClass}&adt=${parseInt(searchData.AdultCount) - parseInt(senior)}&chd=${searchData.ChildCount}&infs=${searchData.InfantCount}&sen=${senior}&infl=0&${preferredAirline ? `pa=${preferredAirline[0]}` : `pa=null`}&direct=${searchData?.DirectFlight}`
        } else {
            return `?cur=${currency?.name}&triptype=${searchData.JourneyType}&from1=${searchData?.Segments[0]?.Origin}&to1=${searchData?.Segments[0]?.Destination}&depart=${DT}&from2=${searchData?.Segments[1]?.Origin}&to2=${searchData?.Segments[1]?.Destination}&return=${RT}&class=${searchData?.Segments[0]?.FlightCabinClass}&adt=${parseInt(searchData.AdultCount) - parseInt(senior)}&chd=${searchData.ChildCount}&infs=${searchData.InfantCount}&sen=${senior}&infl=0&direct=${searchData?.DirectFlight}`
        }
    }

    if (searchData.JourneyType === '3') {
        let urlString;
        if (preferredAirline) {
            urlString = `?cur=${currency?.name}&triptype=${searchData.JourneyType}&class=${searchData.Segments[0].FlightCabinClass}&adt=${parseInt(searchData.AdultCount) - parseInt(senior)}&chd=${searchData.ChildCount}&infs=${searchData.InfantCount}&sen=${senior}&infl=0&seglen=${searchData.Segments.length}&${preferredAirline ? `pa=${preferredAirline[0]}` : `pa=null`}&direct=${searchData?.DirectFlight}`
        } else {
            urlString = `?cur=${currency?.name}&triptype=${searchData.JourneyType}&class=${searchData.Segments[0].FlightCabinClass}&adt=${parseInt(searchData.AdultCount) - parseInt(senior)}&chd=${searchData.ChildCount}&infs=${searchData.InfantCount}&sen=${senior}&infl=0&seglen=${searchData.Segments.length}&direct=${searchData?.DirectFlight}`
        }
        let segsString = "";
        searchData.Segments.map((seg, idx) => {
            let temp = seg.PreferredDepartureTime.split('T')[0]
            let DT = moment(new Date(temp)).format('YYYY-MM-DD')
            if (segsString === "") {
                return segsString = `&from${idx + 1}=${seg.Origin}&to${idx + 1}=${seg.Destination}&depart${idx + 1}=${DT}`
            } else {
                return segsString += `&from${idx + 1}=${seg.Origin}&to${idx + 1}=${seg.Destination}&depart${idx + 1}=${DT}`
            }
        })
        return urlString + segsString
    }
}