import React, { Fragment, useEffect, useState } from "react";
import {
  AnchorDirectionShape,
  DateRangePicker,
  FocusedInputShape,
  SingleDatePicker,
} from "react-dates";
import { DateRage } from "./StaySearchForm";
import { FC } from "react";
// import { Listbox } from "@headlessui/react";
// import { CheckIcon } from "@heroicons/react/24/solid";
import { TimeRage } from "./RentalCarSearchForm";
import useWindowSize from "hooks/useWindowResize";
// import ButtonSubmit from "./ButtonSubmit";
// import { PathName } from "routers/types";
import useNcId from "hooks/useNcId";
import { JourneyType } from "utils/enums";
import { useDispatch, useSelector } from "react-redux";
// import { flightsFound, getTraceId } from "app/actions/flightActions";
import { searchFlights } from "services/flightRequests";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
// import { searchFlights } from "app/actions/flightActions";
import styles from './styles/flightSearchForm.module.css';
import "./styles/flightsearchform.css"
// type Fields = "pickUp" | "dropOff";

export interface RentalCarDatesRangeInputProps {
  handleClick?: Function;
  defaultDateValue: DateRage;
  defaultTimeValue: TimeRage;
  defaultFocus?: FocusedInputShape | null;
  onChange?: (data: { stateDate: DateRage; stateTimeRage: TimeRage }) => void;
  onFocusChange?: (focus: FocusedInputShape | null) => void;
  fieldClassName?: string;
  wrapFieldClassName?: string;
  className?: string;
  numberOfMonths?: number;
  isFullWidth?: boolean,
  anchorDirection?: AnchorDirectionShape;
  buttonSubmitHref?: any;
  dropOffLocationType?: any;
  index?: number,
  hasButtonSubmit?: boolean;
  isFlight?: boolean;
  setIsFlightsLoading?: any,
}

const RentalCarDatesRangeInput: FC<RentalCarDatesRangeInputProps> = ({
  defaultDateValue,
  defaultTimeValue,
  dropOffLocationType,
  index,
  isFlight = false,
  onChange,
  isFullWidth,
  setIsFlightsLoading,
  defaultFocus = null,
  onFocusChange,
  className = "",
  fieldClassName = "[ nc-hero-field-padding ]",
  wrapFieldClassName = "flex relative",
  numberOfMonths,
  anchorDirection,
  buttonSubmitHref = "/listing-car",
  hasButtonSubmit = true,
  handleClick
}) => {
  const [focusedInput, setFocusedInput] = useState(defaultFocus);
  const [singleFocus, setFocus] = useState(() => defaultFocus === 'startDate' ? true : false)
  const [urlSearchParams]: any = useSearchParams()
  const flightActions = import('../../app/actions/flightActions');
  const searchActionTypes = import('../../app/actions/searchActions');
  const [stateDate, setStateDate] = useState(defaultDateValue);
  const { searchData } = useSelector((state: any) => state.searchReducer)
  const { userLocation } = useSelector((state: any) => state.userReducer)
  const [stateTimeRage] = useState(defaultTimeValue);
  const [singleDate, setSingleDate] = useState(moment(urlSearchParams.get('depart') || searchData?.Segments[0]?.PreferredDepartureTime) || null);
  const startDateId = useNcId();
  const endDateId = useNcId();
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const handleSearch = async (e: any) => {
    e.preventDefault()
    setIsFlightsLoading(true)
    searchActionTypes.then(actions => dispatch(actions.endTimer()))
    // let data = await fetch('https://ipinfo.io/?token=0b2cd13db75132');
    // let countryData = await data.json();
    const res = await searchFlights({ data: searchData, country: userLocation.country });
    if (res?.status === 200) {
      searchActionTypes.then(actions => dispatch(actions.getTraceId(res?.traceId)))
      flightActions.then(actions => dispatch(actions.flightsFound({ flights: res?.results, error: res?.error })))
      setIsFlightsLoading(false);
      searchActionTypes.then(actions => dispatch(actions.startTimer()))
    } else {
      searchActionTypes.then(actions => dispatch(actions.getTraceId(res?.traceId)))
      flightActions.then(actions => dispatch(actions.flightsFound({ flights: res?.results, error: res?.error })))
      setIsFlightsLoading(false)
    }
  }


  //
  useEffect(() => {
    setStateDate(defaultDateValue);
  }, [defaultDateValue]);

  useEffect(() => {
    setFocusedInput(defaultFocus);
    if (defaultFocus === 'startDate') {
      setFocusedInput('startDate')
      setFocus(true)
    }
  }, [defaultFocus]);

  const windowSize = useWindowSize();

  const handleDateFocusChange = (focus: FocusedInputShape | null) => {
    setFocusedInput(focus);
    onFocusChange && onFocusChange(focus);
  };

  const inputRenderDate = () => {
    // if (urlSearchParams.get('depart')) return <>{moment(new Date(urlSearchParams.get('depart'))).format('DD MMM YYYY')}</>
    if (searchData.JourneyType === JourneyType.OneWay || searchData.JourneyType === JourneyType.MultiStop) {
      if (!singleDate || singleDate.toDate().toString() === 'Invalid Date') {
        return <>
          {moment().format('DD MMM YYYY')}
        </>
      } else {
        return <>{moment(singleDate).format('DD MMM YYYY')}</>
      }
    } else {
      if (stateDate.startDate) {
        moment(singleDate).format('DD MMM YYYY')
        return <>{moment(stateDate?.startDate).format('DD MMM YYYY') || moment()}</>
      } else {
        return <span className="text-gray-500">Depart</span>
      }
    }
  }
  const outputRenderDate = () => {
    // if (urlSearchParams.get('return')) return <>{moment(new Date(urlSearchParams.get('return'))).format('DD MMM YYYY')}</>
    if (searchData.JourneyType === JourneyType.OneWay || searchData.JourneyType === JourneyType.MultiStop) {
      if (!singleDate || singleDate.toDate().toString() === 'Invalid Date') {
        return <>
          {moment().format('DD MMM YYYY')}
        </>
      } else {
        return <>{moment(singleDate).format('DD MMM YYYY')}</>
      }
    } else {
      if (stateDate.endDate) {
        return <>{moment(stateDate?.endDate).format('DD MMM YYYY') || moment()}</>
      } else {
        return <span className="text-gray-500">Return</span>
      }
    }
  }




  const getClassName = () => {
    // searchData.JourneyType !== JourneyType.OneWay || searchData.JourneyType !== JourneyType.MultiStop ?
    //       focused ? "nc-hero-field-focused" :
    //         " " :
    //       singleFocus ?
    //         'nc-hero-field-focused'
    //         : ''
    if (searchData?.JourneyType === JourneyType.Return) {
      if (focusedInput === "startDate") {
        return `nc-hero-field-focused  ${styles.borderColor}`
      } else {
        return `nc-hero-field-focused ${styles.borderColor}`
      }
    } else {
      if (searchData?.JourneyType === JourneyType.OneWay) {
        if (singleFocus) {
          return `nc-hero-field-focused ${styles.borderColor}`
        } else {
          return ''
        }
      }
    }
  }

  const renderInputpickUpDate = () => {
    const focused = focusedInput === "startDate";
    return (
      <div
        className={`flex h-full w-full p-[10px] justify-left items-center space-x-3 cursor-pointer ${focused ? getClassName() : ""}`}
      >
        {/* <div className="text-neutral-300 dark:text-neutral-400  ">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="nc-icon-field"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#F70F36"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={1.5}
              d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
            />
          </svg>
        </div> */}
        <div className="flex flex-col items-start justify-center h-full">

          <div className=" inline-flex items-center font-medium">
            <span className="flex-shrink-0 text-sm md:text-base lg:text-lg">

              {inputRenderDate()}

            </span>
            {/* {stateDate.startDate && renderEditTime("pickUp")} */}
          </div>

          <span className="block mt-1 text-xs sm:text-sm text-neutral-400 font-light">
            {stateDate.startDate ? "Depart" : ``}
          </span>
        </div>
      </div>
    );
  };

  const renderInputdropOffDate = () => {
    const focused = focusedInput === "endDate";
    return (
      <
        // div
        // className={`flex relative flex-[1.8] items-center cursor-pointer ${searchData.JourneyType !== JourneyType.OneWay ? focused ? "nc-hero-field-focused" : " " : ''
        //   }`}
        >
        <div className={`flex h-full w-full p-[10px] justify-left items-center space-x-3 cursor-pointer ${focused ? getClassName() : ""}`}>
          {dropOffLocationType !== JourneyType.OneWay && dropOffLocationType !== JourneyType.MultiStop &&
            <>
              {/* <div className="text-neutral-300 dark:text-neutral-400">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="nc-icon-field "
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="#F70F36"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </div> */}
              <div className="flex flex-col items-start h-full">
                <div className=" inline-flex items-center text-lg xl:text-base font-medium">
                  <span className="flex-shrink-0 text-sm md:text-base lg:text-lg">
                    {outputRenderDate()}
                    {/* {urlSearchParams.get('return') ? moment(new Date(urlSearchParams.get('return'))).format('DD MMM YYYY') : stateDate.endDate
                      ? moment(stateDate.endDate).format("DD MMM YYYY")
                      : <span className="text-gray-500 font-normal"> Return</span>} */}
                  </span>
                  {/* {stateDate.endDate && renderEditTime("dropOff")} */}
                </div>
                <span className="block mt-1 text-xs sm:text-sm text-neutral-400 font-light">
                  {stateDate.endDate ? "Return" : ``}
                </span>
              </div>
            </>
          }
        </div>
        {/* {
          hasButtonSubmit && (
            dropOffLocationType === JourneyType.MultiStop ?
              index === 0 &&
              <div className="pr-2 xl:pr-4 relative z-20">
                <button onClick={(e) => {
                  if (isFullWidth) {
                    handleSearch(e);
                  } else {
                    navigate(buttonSubmitHref)
                  }
                }}
                  className='h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none'
                >
                  <span className="mr-3 md:hidden">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
              : < div className="pr-2 xl:pr-4 relative z-20">
                <button onClick={(e) => {
                  if (isFullWidth) {
                    handleSearch(e);
                  } else {
                    navigate(buttonSubmitHref, { state: searchData.Segments[0].Origin && searchData.Segments[0].Destination ? true : false })
                  }
                }}
                  className='h-14 md:h-16 w-full md:w-16 rounded-full bg-primary-6000 hover:bg-primary-700 flex items-center justify-center text-neutral-50 focus:outline-none'
                >
                  <span className="mr-3 md:hidden">Search</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    />
                  </svg>
                </button>
              </div>
          )
        } */}
      </
      // div 
      >
    );
  };


  const handleMultiDatesSelection = (date: any) => {
    let segments: any = [];
    searchData?.Segments?.map((segment: any, idx: any) => {
      if (index === idx) {
        segments.push({ ...segment, PreferredDepartureTime: moment(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString()?.slice(0, -5), PreferredArrivalTime: moment(new Date(date).setUTCHours(0, 0, 0, 0)).toISOString()?.slice(0, -5) })
        setFocus(false)
      } else {
        segments.push(segment)
        setFocus(false)
      }
    })
    import('../../app/actions/searchActions').then(searchAction => dispatch(searchAction.getDatesSegment(segments)))
  }

  return (
    <div
      className={`h-full ${!!focusedInput ? "nc-date-focusedInput" : "nc-date-not-focusedInput"
        }  `}
    >
      <div className="h-full relative flex " onClick={() => {
        if (!isFullWidth) {
          window.scrollTo({
            top: 200,
            behavior: 'smooth'
          });
        }
      }}>

        {searchData.JourneyType === JourneyType.OneWay ?
          <div className="w-full h-full z-[200]">
            <SingleDatePicker
              noBorder
              date={singleDate.toDate().toString() === 'Invalid Date' ? moment() : moment(singleDate)}
              onDateChange={(date: any) => {
                if (index === undefined) {
                  setSingleDate(date)
                  setFocus(false)
                  import('../../app/actions/searchActions').then(actions => dispatch(actions.getDates(date, 'single')))
                } else {
                  handleMultiDatesSelection(date)
                }
              }}
              numberOfMonths={1}
              reopenPickerOnClearDate
              focused={singleFocus}
              // focused={focusedInput === 'startDate' ? true : false}
              onFocusChange={(e) => {
                handleDateFocusChange(e ? 'startDate' : "endDate")
                setFocus((val: any) => val ? !singleFocus : !singleFocus)
                onFocusChange && onFocusChange(e ? 'startDate' : 'endDate');
              }}
              id="your_unique_id"
              daySize={windowSize.width > 1279 ? 54 : 44}
              hideKeyboardShortcutsPanel
            // anchorDirection={anchorDirection}
            />
          </div>
          :
          <div className="w-fit z-[200] date-range-picker">
            <DateRangePicker
              startDate={moment(stateDate.startDate)}
              endDate={moment(stateDate.endDate)}
              focusedInput={focusedInput}
              onDatesChange={(date) => {
                setStateDate(date);
                onChange && onChange({ stateDate: date, stateTimeRage });
              }}
              onFocusChange={handleDateFocusChange}
              startDateId={startDateId}
              endDateId={endDateId}
              daySize={windowSize.width > 1279 ? 44 : 34}
              orientation={"horizontal"}
              // showClearDates
              noBorder
              hideKeyboardShortcutsPanel
              numberOfMonths={
                numberOfMonths || (windowSize.width < 580 ? 1 : undefined)
              }
              anchorDirection={"left"}
              reopenPickerOnClearDates
            />
          </div>
        }
      </div>

      <div className={`h-full relative ${searchData.JourneyType === JourneyType.Return ? "top-[-102%] sm:top-[-99.5%] lg:top-[-101.5%]" : "top-[-100%]"} depart border border-slate-400 rounded-md`}>
        {renderInputpickUpDate()}
      </div>
      {dropOffLocationType !== JourneyType.OneWay && dropOffLocationType !== JourneyType.MultiStop && <div className="sm:h-full h-[95%] relative top-[-100%] return border border-slate-400 rounded-md">
        {renderInputdropOffDate()}
      </div>}
    </div >
  );
};

export default RentalCarDatesRangeInput;
